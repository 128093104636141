import ProductListItem from "./product-list-item";
import Pagination from "../app/list/pagination";
import FormInputCheckbox from "../app/form/FormInputCheckbox";


export default function ProductList({ items, totalCount, currentPage, handlePageClick, includeArchived, changeIncludeArchived, productsLoaded, getProducts }) {
    const perPage = process.env.REACT_APP_PAGE_SIZE;

    return <div className="cell small-12">
        {productsLoaded && <div>
            <FormInputCheckbox className="margin__left-right--xxs product-list__include-archived" id="chbIncludeArchived"
                               label="Show archived" labelClassName="margin__left-right--xxs" value={includeArchived}
                               onChange={changeIncludeArchived}/>
            <div className="grid-x small-12 grid-margin-x product-list__header show-for-medium">
                <div className="cell small-10">
                    <div className="grid-x">
                        <h4 className="cell small-6 product-list__col-title">Name</h4>
                        <h4 className="cell small-4 product-list__col-title">Discount</h4>
                    </div>
                </div>
                <h4 className="cell small-2 product-list__col-title"></h4>
            </div>
            {items && items.map((item, i) => <ProductListItem product={item}
                                                              getProducts={getProducts}
                                                                key={i}/>)}
            {items && totalCount > perPage &&
            <div className="grid-x">
                <div className="cell small-12 display--flex justifyContent--center margin__top--xl">
                    <Pagination currentPage={currentPage} pageSize={perPage} totalResults={totalCount}
                                description={'products'} onPageChanged={handlePageClick}/>
                </div>
            </div>
            }
        </div>
        }
        {!productsLoaded && <p>Loading products...</p> }

    </div>
}