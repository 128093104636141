import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Router, Link } from '@reach/router';
import * as Navigation from '../../../modules/navigation';
import Logo from '../../../assets/images/icon-logo.svg';
import IconBack from '../../../assets/images/icon-back.svg';
import IconBurger from '../../../assets/images/icon-burger.svg';
import ProfileBubble from './ProfileBubble';

export default function Header({ setShowMobileMenu, showBack = false }) {
    const { user, isAuthenticated, isLoading } = useAuth0();
    const [initial, setInitial] = useState(null);
    const [showBubble, setShowBubble] = useState(false);

    function showMobileMenu(){
        setShowMobileMenu(true)
    }

    useEffect(() => {
        if (user) {
            setInitial(user.nickname.charAt(0));
        }
    }, [user]);

    function closeMenu(){
		setShowBubble(false);
	}

    return <header className="header">
        <div className="grid-container padding__top-bottom--xs">
            <div className="display--flex justifyContent--space-between header__wrapper">
                <div className="display--flex alignItems--center">
                    <div>
                        {showBack &&
                            <Router primary={false} className="header__back-link flex alignItems--center">
                                <BackLink path={Navigation.root.path} text="Back" to={Navigation.back.to} />
                            </Router>
                        }
                    </div>
                </div>
                {showBubble &&
                    <div className="bubble-transparency" onClick={closeMenu}></div>
                }
                <div className="display--flex justifyContent--flex-end alignItems--center">
                    { !isLoading && isAuthenticated && 
                        <div className="header__profile">
                            <button className="button header__avatar textAlign--center textTransform--uppercase" name="avatar" onClick={() => setShowBubble(!showBubble)}>{initial}</button>
                            { showBubble &&
                                <ProfileBubble />
                            }
                            <p className="margin__left--xxs header__avatar__user-name">{user.name}</p>
                            
                        </div>
                    }
                    <button className="header__menu-btn" onClick={showMobileMenu}>
                        <img src={IconBurger} alt="Menu button" />
                    </button>
                </div>
            </div>
        </div>
    </header>
};

const BackLink = props => (
    <Link className="header__back-link-text flex textDecoration--none" to={props.to}>
        <img className="header__back-link-icon" src={IconBack} alt="Back" />
        {props.text}
    </Link>
);