import UploadSpreadsheet from "../../symbols/report-wizard/UploadSpreadsheet";
import { ReportType } from "../../domain/reports/ReportType";
import ReportWizard from "../../symbols/report-wizard/ReportWizard";
import { ReportWizardProvider } from "../../symbols/report-wizard/ReportWizardProvider";
import SpecifyColumnMappings from "../../symbols/report-wizard/mapping/SpecifyColumnMappings";
import ManufacturerPracticeMapper from "../../symbols/report-wizard/mapping/ManufacturerPracticeMapper";
import WholesalerMapper from "../../symbols/report-wizard/mapping/WholesalerMapper";
import ManufacturerProductMapper from "../../symbols/report-wizard/mapping/ManufacturerProductMapper";

export default function ManufacturerReportWizardPage({ reportId, reportUploadId }) {

    const stepLabels = [
        "Manufacturer Upload",
        "Column Mappings",
        "Practices",
        "Wholesalers",
        "Products"
    ]

    return <ReportWizardProvider reportId={reportId} reportUploadId={reportUploadId} reportType={ReportType.Manufacturer}>
        <ReportWizard stepLabels={stepLabels}>
            <UploadSpreadsheet />
            <SpecifyColumnMappings header={'Map columns for manufacturer'} />
            <ManufacturerPracticeMapper />
            <WholesalerMapper />
            <ManufacturerProductMapper />
        </ReportWizard>
    </ReportWizardProvider>
}

