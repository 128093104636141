import FormSelect from "../../app/form/FormSelect";

export default function MappingListItem({ mapping, options, specifiedMappings, updateMapping, validationLookup }) {

    function getDisplayValue(option) {
        if(option?.sampleValue) {
            return `${option.name} - ${option.sampleValue}`;
        }
        return option.name;
    }

    const selectOptions = options.map((o, i) => {
        return {
            value: o.number,
            text: getDisplayValue(o)
        }
    })
    const allOptions = [ { value: 0, text: 'Please select' }, ...selectOptions ]

    return <div className="mapping__list__item grid-x">
        <div className="cell small-12 medium-6">
            <p className="mapping__list__item__display-value">{mapping?.displayValue}</p>
        </div>
        <div className="cell small-12 medium-6">
            <FormSelect value={specifiedMappings ? specifiedMappings[mapping.value] : undefined} 
                isValid={validationLookup ? validationLookup[mapping.value] : true}
                className="mapping__list__item__picker" 
                name={'Mapping option'} 
                onChange={(e) => updateMapping(mapping, e.target.value)}
                options={allOptions} />
        </div>
    </div>
}