import { useState, useEffect } from "react";
import { ContinueAction, useReportWizard } from "../../ReportWizardProvider";

export default function useValueMapping(continueAction = ContinueAction.None) {
    const { reportUploadId, mappingData, setMappingData, setContinueAction, setValidToMoveForward } = useReportWizard();
    const [validationLookup, setValidationLookup] = useState();

    useEffect(() => {
        setContinueAction(continueAction);
    }, [continueAction])

    function updateMapping(mapping, value) {
        const copyMapping = { ...mapping };
        copyMapping.mappingValue = value === 'Please select' ? null : value;
        const index = mappingData.mappings.findIndex(x => x.id === mapping.id);
        const copyMappings = { ...mappingData };
        copyMappings.mappings[index] = copyMapping;
        setMappingData(copyMappings);
        validate(copyMappings);
    }

    function validate(mappingData) {
        const newValidationLookup = {};
        const { mappings } = mappingData;
        for (const mapping of mappings) {
            newValidationLookup[mapping.id] = mappingValueUnique(mappingData, mapping.mappingValue);
        }
        setValidationLookup(newValidationLookup);
        const isValid = Object.values(newValidationLookup).every(x => x === true);
        setValidToMoveForward(isValid);
    }

    function mappingValueUnique(mappingData, mappingValue) {
        if (mappingValue === null) {
            return true;
        }
        return mappingData.mappings.filter(x => x.mappingValue !== null && x.mappingValue === mappingValue).length <= 1;
    }

    return [reportUploadId, mappingData, setMappingData, updateMapping, validate, validationLookup];
}