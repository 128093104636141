import React, { useState, useEffect } from "react";
import { useHttp } from '../../../context/HttpProvider';

export default function Toast() {
	const { showToast, toastMessage } = useHttp();

	useEffect(() => {
    }, [showToast]);
    
	return <>
		<div className={`${showToast === false ? "toast--fadeOut" : "toast__container toast--fadeIn"}`}>
				<div className="toast">
						<div className="toast__loader"></div>
						<h2>{toastMessage}</h2>
				</div>
			</div>
	</>
};