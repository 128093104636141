import React from "react";
import Modal from '../../../symbols/app/modal/Modal';
import ButtonPrimary from "../../app/button/ButtonPrimary";

export default function ModalMappingErrors({ title, isActive, onClose, onOk, errors }) {
    return isActive && <Modal onClose={() => onClose(false)}>
        <div className="mapping__modal-errors">
            <h3 className="modal__content-title mapping__modal-errors__title">{title} - [{errors?.length}]</h3>
            <div className="mapping__modal-errors__list margin__top--m">
                <ul id="mapping-error-list">
                    {errors.map((error, i) => <li key={i}>{error}</li>)}
                </ul>
            </div>
        </div>
        <div className="textAlign--right">
            <ButtonPrimary id="btnMappinngErrorsOk" onClick={onOk} text="Ok" className="button--regular margin__top--m" />
        </div>
    </Modal>
}