import ReportDataListItem from "./report-data-list-item";

export default function ReportsDataList({ type, items, totalFormatted, reportId }) {
    return <div className="cell small-12 report-data-list">
        <div className="grid-x ">
            <div className="cell small-12 medium-3 show-for-small">
                <p className="report-data-list__type">{type}</p>
            </div>
            <div className="cell small-12 medium-2 show-for-medium display--flex justifyContent--center alignItems--center">
                <p className="report-data-list__head">Upload</p>
            </div>
            <div className="cell small-12 medium-6 show-for-medium display--flex alignItems--center">
                <p className="report-data-list__head">File</p>
            </div>
            <div className="cell small-12 medium-1 show-for-medium display--flex justifyContent--right alignItems--center padding__right--xs">
                <p className="report-data-list__head">Total</p>
            </div>
        </div>
        { items && items.map((item, i) => <ReportDataListItem reportId={reportId} key={item.reportUploadId} id={item.reportUploadId} type={type} name={item.name} filename={item.filename} reconciled={item.reconciled} totalFormatted={item.totalSpentFormatted} />) }
        <div className="report-data-list__total__sum show-for-medium textAlign--right">
            {totalFormatted && <p>{totalFormatted}</p>}
            {totalFormatted && <div className="report__section__field-empty"></div>}
        </div>
        <div className="report-data-list__total__sum show-for-small hide-for-medium textAlign--right">
            {totalFormatted && <p>Total {totalFormatted}</p>}
            {!totalFormatted && <div className="report__section__field-empty"></div>}
        </div>
    </div>
}