import {useState, useEffect} from "react";

export default function Tabs({labels, children, openTabsAtIndex, setOpenTabsAtIndex}) {
    const [activeIndex, setActiveIndex] = useState(0);


    useEffect(() => {
        if(openTabsAtIndex!=null){
            setActiveIndex(openTabsAtIndex);
            setOpenTabsAtIndex(null);
        }
    }, [openTabsAtIndex]);

    return <div>
        {
            labels.length === children.length && <div>
                <div id="tabs" className="tabs">
                    {
                        labels.map((label, i) => {
                            return <div onClick={() => setActiveIndex(i)} key={i} className={`tabs__tab ${i === activeIndex ? "tabs__tab--active" : ""}`}>
                                {
                                    <div >
                                        <p>{label}</p>
                                    </div>
                                }
                            </div>
                        })
                    }
                </div>

                <div className="tabs__content">
                    {children.map((child, i) => {
                        return <div key={`content-${i}`}>
                            {
                                activeIndex === i && child
                            }
                        </div>
                    })}
                </div>
            </div>
        }
        {
            labels.length !== children.length && <p>The length of the labels and children do not match. Please check your data.</p>
        }
    </div>
}
