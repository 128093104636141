import ButtonLink from "../app/button/ButtonLink";
import { RenderByRole } from "../app/auth/RenderByRole";
import Roles from "../../domain/auth/Roles";

export default function ArchiveButton({ onClick, type }) {
    return <div className="grid-x archive__button-holder">
        <div className="cell small-12 display--flex justifyContent--center">
            <RenderByRole>  
                <ButtonLink id="archive-button" supportedrole={Roles.AdminUser} text={`Archive ${type}`} className="button--link__archive" onClick={onClick} />
            </RenderByRole>
        </div>
    </div>
}