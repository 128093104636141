import ReportItem from "./report-item";
import React from "react";

export default function ReportSection({ reportSection, reportId, practiceId = null }) {
    return <div className="cell small-12 report ">
        <div className="cell small-12 display--flex justifyContent--center show-for-small-only">
            <p className="report__section__title">{reportSection.title}</p>
        </div>
        <div className="grid-x report__section__header show-for-medium">
            <div className="cell small-12 medium-8">
                <p className="report__section__type">{reportSection.title}</p>
            </div>
            <div className="cell small-12 medium-2 textAlign--center">
                <p className="report__section__head textAlign--left">Spent</p>
            </div>
            <div className="cell small-12 medium-2 textAlign--center">
                <p className="report__section__head textAlign--right">Discount</p>
            </div>
        </div>
        <div className="padding__top-bottom--m">
            {reportSection && reportSection.lines && reportSection.lines.map((line, i) => <ReportItem reportLine={line} reportId={reportId} key={i} practiceId={practiceId} />)}
        </div>

        <div className="grid-x report__section__totals show-for-small-only">
            <div className="cell small-6 textAlign--center report__section__total__wrapper--mobile">
                <p className="report__section__head show-for-small-only">Total Spent</p>
                {reportSection.spentTotalFormatted && <p className="report__section__total--mobile">{reportSection.spentTotalFormatted}</p>}
                {!reportSection.spentTotalFormatted && <div className="report__section__field-empty"></div>}
            </div>
            <div className="cell small-6 textAlign--center report__section__total__wrapper--mobile">
                <p className="report__section__head show-for-small-only">Total Rebate</p>
                {reportSection.rebateTotalFormatted && <p className="report__section__total--mobile">{reportSection.rebateTotalFormatted}</p>}
                {!reportSection.rebateTotalFormatted && <div className="report__section__field-empty"></div>}
            </div>
        </div>
        <div className="grid-x report__section__totals show-for-medium">
            <div className="cell small-12 medium-8 report__section__total__sum textAlign--right show-for-medium">
            </div>
            <div className="cell small-4 medium-2 report__section__total__sum">
                {reportSection.spentTotalFormatted && <p>{reportSection.spentTotalFormatted}</p>}
                {!reportSection.spentTotalFormatted && <div className="report__section__field-empty"></div>}
            </div>
            <div className="cell small-4 medium-2 report__section__total__sum display--flex justifyContent--flex-end">
                {reportSection.rebateTotalFormatted && <p>{reportSection.rebateTotalFormatted}</p>}
                {!reportSection.rebateTotalFormatted && <div className="report__section__field-empty"></div>}
            </div>
        </div>
    </div>
}