import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import IconLogoutBlue from '../../../assets/images/icon-logout-blue.svg';

export default function ProfileBubble() {
    const { user, logout } = useAuth0();

    return <div className="header__profile-bubble">
        <div className="profile-bubble textAlign--left">
            <div className="profile-bubble__user">
                <span className="profile-bubble__user-name display--block">{user.name}</span>
                <span className="profile-bubble__user-email display--block">{user.email}</span>
            </div>
            <div className="profile-bubble__logout">
                <button className="button profile-bubble__logout-button display--flex alignItems--center" name="log-out" onClick={() => logout({ returnTo: window.location.origin })}>
                    <img className="button profile-bubble__logout-icon" src={IconLogoutBlue} alt="Logout" />
                    Logout
                </button>
            </div>
        </div>
    </div>
};