import React, { useState, useEffect } from "react";
import ContentArea from "../../symbols/app/content/content-area";
import {RenderByRole} from "../../symbols/app/auth/RenderByRole";
import ButtonSecondary from "../../symbols/app/button/ButtonSecondary";
import Roles from "../../domain/auth/Roles";
import BasicList from "../../symbols/app/list/basic-list";
import * as Navigation from "../../modules/navigation";
import Pagination from "../../symbols/app/list/pagination";
import {useHttp} from "../../context/HttpProvider";
import HttpStatusCodes from "../../modules/httpStatusCodes";
import FormInputCheckbox from "../../symbols/app/form/FormInputCheckbox";
import ModalSingleInput from "../../symbols/app/modal/ModalSingleInput";
import {navigate} from "@reach/router";

export default function WholesalersPage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [showCreateWholesalerModal, setShowCreateWholesalerModal] = useState(false);
    const [createWholesalerError, setCreateWholesalerError] = useState(null);
    const { wholesalersApi } = useHttp();
    const [wholesalers, setWholesalers] = useState();
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [includeArchived, setIncludeArchived] = useState(false);
    const [perPage, setPageSize] = useState(process.env.REACT_APP_PAGE_SIZE);
    const [showError, setShowError] = useState(false);

    async function getWholesalers(skip, archived){
        var response = await wholesalersApi.list(archived != undefined ? archived : includeArchived, skip, perPage);
        if (response) {
            if(response.status !== undefined && response.status !== HttpStatusCodes.Ok) {
                setShowError(true);
            }
            else {
                setShowError(false);
                setWholesalers(response.wholesalers);
                setTotalCount(response.totalCount);
                setHasLoaded(true);
            }
        }
    }

    async function handlePageClick(data){
        let selected = data.selected;
        setCurrentPage(selected)
        await getWholesalers(selected*perPage)
    }

    async function changeIncludeArchived(include) {
        setIncludeArchived(include);
        await getWholesalers(0, include)
    }

    async function createWholesaler(value) {
        var payload = {
            name: value
        };

        let response = await wholesalersApi.create(payload);
        if (response.status === HttpStatusCodes.Created) {
            navigate(`${Navigation.wholesalerDetail.to}${response.data.wholesalerId}`);
        } else {
            setCreateWholesalerError(response.data.Errors[0]);
        }
    }

    useEffect(() => {
        if(wholesalersApi!=null){
            getWholesalers(0, includeArchived);
        }
    }, [wholesalersApi]);

    return <ContentArea>
        <div className="content-area__header">
            <div className="content-area__header__left-container">
                <h1 className="margin__top--s">Wholesalers</h1>
            </div>
            <div className="content-area__header__right-container">
                <RenderByRole>
                    <ButtonSecondary id="btnAddWholesaler" supportedrole={Roles.AdminUser} text="Add new wholesaler" className="button--regular button--regular__add  display--flex" onClick={() => setShowCreateWholesalerModal(!showCreateWholesalerModal)} />
                </RenderByRole>
                <FormInputCheckbox id="chbIncludeArchived" label="Show archived" labelClassName="margin__left-right--xxs" value={includeArchived} onChange={() => changeIncludeArchived(!includeArchived)}/>
            </div>
            
        </div>
        {hasLoaded && !showError &&
            <>
                <BasicList items={wholesalers} navigatesTo={`${Navigation.wholesalerDetail.to}`} />
                {wholesalers && totalCount > perPage &&
                <div className="grid-x">
                    <div className="cell small-12 display--flex justifyContent--center margin__top--xl">
                        <Pagination currentPage={currentPage} pageSize={perPage} totalResults={totalCount} description={'practices'} onPageChanged={handlePageClick}/>
                    </div>
                </div>
                }
            </>
        }
        {
            showError &&
            <p>Oops, we could not retrieve the wholesalers, please try again.</p>
        }
        <ModalSingleInput title="Add a New Wholesaler" label="Name" isActive={showCreateWholesalerModal} onClose={setShowCreateWholesalerModal} onSubmit={createWholesaler} error={createWholesalerError}/>
    </ContentArea>
}