import React, { useState, useEffect } from "react";
import ContentArea from "../../symbols/app/content/content-area"
import ButtonSecondary from '../../symbols/app/button/ButtonSecondary';
import ModalSingleInput from "../../symbols/app/modal/ModalSingleInput";
import ReportsList from "../../symbols/app/list/reports-list";
import { useHttp } from '../../context/HttpProvider';
import HttpStatusCodes from '../../modules/httpStatusCodes';
import * as Navigation from '../../modules/navigation';
import {navigate} from '@reach/router';
import { RenderByRole } from "../../symbols/app/auth/RenderByRole";
import Roles from '../../domain/auth/Roles';

export default function ReportsPage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [showCreateReportModal, setShowCreateReportModal] = useState(false);
    const [createReportError, setCreateReportError] = useState(null);
    const [reports, setReports] = useState();
    const [totalCount, setTotalCount] = useState(0);
    const { reportsApi } = useHttp();
    const perPage = process.env.REACT_APP_PAGE_SIZE;

    async function getReports(skip){
        var response = await reportsApi.getReports(skip, perPage);
        if (response) {
            setReports(response.reports);
            setTotalCount(response.totalCount);
            setHasLoaded(true);
        }
    };

    async function createReport(value){
        var payload = {
            name : value,
        };

        let response = await reportsApi.create(payload);
        if (response.status === HttpStatusCodes.Created) {
            navigate(`${Navigation.reportDetail.to}${response.data.reportId}`);
        } else {
            setCreateReportError(response.data.Errors[0]);
        }
    }

    useEffect(() => {
        if (reportsApi != null) {
            getReports(0);
        }
    }, [reportsApi]);

    return <ContentArea>
        <div className="content-area__header">
            <div className="content-area__header__left-container">
                <h1 className="margin__top--s">Monthly Reports</h1>
            </div>
            <div className="content-area__header__right-container">
                <RenderByRole>
                    <ButtonSecondary id="btnAddReport" supportedrole={Roles.AdminUser} text="Add new monthly report" className="button--regular button--regular__add  display--flex" onClick={() => setShowCreateReportModal(!showCreateReportModal)} />
                </RenderByRole>
            </div>
        </div>

        { hasLoaded &&
            <ReportsList reports={reports} getReports={getReports} totalCount={totalCount} navigationOveride={Navigation.reportDetail.to}/>
        }

        <ModalSingleInput title="Add a New Monthly Report" label="Report name" isActive={showCreateReportModal} onClose={setShowCreateReportModal} onSubmit={createReport} error={createReportError} />
    </ContentArea>
};