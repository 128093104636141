import ArchiveButton from "./ArchiveButton";
import ArchiveInfo from "./ArchiveInfo";
import ModalConfirmation from "../app/modal/ModalConfirmation";

export default function Archive({ target, onClick, type, isModalActive, onArchiveConfirmed, onModalClose, archiveError }) {
    return <>
        {target?.archived && <ArchiveInfo target={target} />}
        {target?.archived === false && <ArchiveButton type={type} onClick={onClick} />}
        <ModalConfirmation action="Archive" entityType={type} entityName={target?.companyName ?? target?.name} isActive={isModalActive} onConfirmed={onArchiveConfirmed} onClose={onModalClose} error={archiveError} />
    </>
}
