import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import * as Navigation from '../../../modules/navigation';
import { useHttp } from '../../../context/HttpProvider';
import Roles from '../../../domain/auth/Roles';
import { Claims } from "../../../modules/auth/Claims";

export default function EnsureCreated() {
    const { authApi } = useHttp();
    const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

    useEffect(() => {
        async function ensureCreated() {
            const accessToken = await getAccessTokenSilently();
            localStorage.setItem(process.env.ACCESS_TOKEN, accessToken)
            if (isAuthenticated && user && user.sub) {
                const result = await authApi.ensureCreated(user.sub);
                if (result && result.userId) {
                    Navigation.navigateToLandingPage(user);
                }
            }
        }
        ensureCreated();
    }, [user, getAccessTokenSilently, isAuthenticated]);

    return <></>;
}
