import IconTrash from '../../../assets/images/icon-trash.svg'
import IconEdit from '../../../assets/images/icon-edit.svg';
import ModalConfirmation from "../modal/ModalConfirmation";
import React, {useState} from "react";
import HttpStatusCodes from "../../../modules/httpStatusCodes";
import {useHttp} from "../../../context/HttpProvider";
import { RenderByRole } from '../auth/RenderByRole';
import Roles from '../../../domain/auth/Roles';
import ModalEditPracticeUser from '../../practices/ModalEditPracticeUser';

export default function PracticeUsersListItem({id, firstName, lastName, fullName, practiceId, emailAddress, getPracticeUsers}) {
    const [showConfirmDeleteUser, setShowConfirmDeleteUser] = useState(false);
    const [showEditUserModal, setShowEditUserModal] = useState(false);
    const [deleteUserError, setDeleteUserError] = useState(false);
    const [editPracticeUserError, setEditPracticeUserError] = useState()
    const { practiceUsersApi } = useHttp();

    async function onDeleteConfirmed() {
        if (practiceUsersApi !== undefined) {
            var response = await practiceUsersApi.delete({userId: id, practiceId: practiceId})
            if (response.status === HttpStatusCodes.Ok) {
                getPracticeUsers();
                setShowConfirmDeleteUser(false);
            } else {
                setDeleteUserError(response.data[0]);
            }
        }
    }

    async function editPracticeUser(data) {
        var payload = {
            firstName: data.firstName,
            lastName: data.lastName,
            emailAddress: data.emailAddress,
            id: id
        };

        let response = await practiceUsersApi.edit(payload);

        if (response.status === HttpStatusCodes.Ok) {
            setShowEditUserModal(false);
            getPracticeUsers(practiceId, 0);
        } else {
            setEditPracticeUserError(response.data.Errors[0]);
        }
    };

    return <div className="grid-x practice-users-list__item">
        <div className="cell small-10 medium-6">
            <div className="grid-x">
                <div className="cell small-12 medium-6">
                    <p className="practice-users-list__field practice-users-list__name">{fullName}</p>
                </div>
                <div className="cell small-12 medium-6">
                    <p className="practice-users-list__field practice-users-list__email-address">{emailAddress}</p>
                </div>
            </div>
        </div>
        <div className="cell small-2 medium-6 display--flex justifyContent--flex-end">
            <img src={IconEdit} className="icnEdit practice-users-list__edit cursor__pointer" onClick={() => setShowEditUserModal(true)}/>
            <RenderByRole>   
                <img supportedrole={Roles.AdminUser} src={IconTrash} className="icnDelete practice-users-list__delete cursor__pointer" onClick={() => setShowConfirmDeleteUser(true)}/>
            </RenderByRole>
        </div>
        
        <ModalConfirmation action="Delete"
                           entityType="Practice User"
                           entityName={name}
                           isActive={showConfirmDeleteUser}
                           onConfirmed={onDeleteConfirmed}
                           onClose={() => setShowConfirmDeleteUser(false)}
                           error={deleteUserError}/>

        <ModalEditPracticeUser isActive={showEditUserModal}
                            onClose={setShowEditUserModal} 
                            onEditPracticeUser={editPracticeUser} 
                            error={editPracticeUserError} 
                            existingFirstName={firstName} 
                            existingLastName={lastName} 
                            existingEmail={emailAddress} />
    </div>
};