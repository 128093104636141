import React from "react";
import Modal from "./Modal";
import ButtonPrimary from "../button/ButtonPrimary";
import ButtonSecondary from '../button/ButtonSecondary';

export default function ModalConfirmation({ action, entityType, entityName, isActive, onClose, onConfirmed, error, ...others }) {

    return <div>{isActive && <Modal onClose={() => onClose(false)}>
        <h3 className="modal__content-title textAlign--center padding__bottom--m">{action} {entityType}?</h3>
        <p className="margin__bottom--s textAlign--center">Are you sure you want to {action.toLowerCase()} {entityName}?</p>
        { error !== "" &&
            <p id="archiveError" className="small input__error">{error}</p>
        }
        <div className="modal__confirmation__button-wrapper">
            <ButtonSecondary text="Cancel" className="button--regular" onClick={() => onClose(false)} />
            <ButtonPrimary id="btnConfirm" text="Confirm" name="confirm-archive-practice" className="button--regular" onClick={onConfirmed} />
        </div>
    </Modal>
    }
    </div>
}