import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Claims } from './Claims';

export function useAppMetaData() {

    const { user, isAuthenticated } = useAuth0();
    const [appMetaData, setAppMetaData] = useState(undefined);

    useEffect(() => {
        if (user && isAuthenticated) {
            const app_metadata = user[Claims.AppMetaData];
            setAppMetaData(app_metadata);
        }
    }, [user, isAuthenticated])
    
    return [appMetaData];
}