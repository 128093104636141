import ValueMappingList from "./ValueMappingList";

export default function MapEntity({data, type, updateMapping, validationLookup}) {
    return <>
        {
            data && data.mappings && data.mappings.length > 0 && <div className="grid-x">
                <h4 className="cell small-12 mapping__header margin__bottom--m">Map {type.toLowerCase()}s</h4>
                <p className="cell small-12 large-7 mapping__explanatory-text padding__bottom--m">Review and map the {type.toLowerCase()}s in your CSV with the {type.toLowerCase()}s below.</p>
                <ValueMappingList className="cell small-12" header={`${type}s`} options={data.options} mappings={data.mappings} updateMapping={updateMapping} validationLookup={validationLookup} />
            </div>
        }
        {
            data && data.mappings && data.mappings.length === 0 && <p>There have been no appropriate {type.toLowerCase()}s setup in the system. Please do this before continuing.</p>
        }
    </>
}