import UploadSpreadsheet from "../../symbols/report-wizard/UploadSpreadsheet";
import { ReportType } from "../../domain/reports/ReportType";
import ReportWizard from "../../symbols/report-wizard/ReportWizard";
import { ReportWizardProvider } from "../../symbols/report-wizard/ReportWizardProvider";
import SpecifyColumnMappings from "../../symbols/report-wizard/mapping/SpecifyColumnMappings";
import ManufacturerMapper from "../../symbols/report-wizard/mapping/ManufacturerMapper";
import WholesalerProductMapper from "../../symbols/report-wizard/mapping/WholesalerProductMapper";

export default function WholesalerReportWizardPage({ reportId, reportUploadId }) {

    const stepLabels = [
        "Wholesaler Upload",
        "Column Mapping",
        "Manufacturers",
        "Products"
    ]

    return <ReportWizardProvider reportId={reportId} reportUploadId={reportUploadId} reportType={ReportType.Wholesaler}>
        <ReportWizard stepLabels={stepLabels}>
            <UploadSpreadsheet />
            <SpecifyColumnMappings header={'Map columns for wholesaler'} />
            <ManufacturerMapper />
            <WholesalerProductMapper />
        </ReportWizard>
    </ReportWizardProvider>
}

