import React, { useState, useEffect } from "react";
import Modal from '../app/modal/Modal';
import InputText from '../app/form/FormInputText';
import ButtonPrimary from '../app/button/ButtonPrimary';

export default function ModalAddPracticeUser({ isActive, onClose, onCreatePracticeUser, error, ...others }) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');

    function onFirstNameChanged(e) {
        setFirstName(e.target.value);
    };

    function onLastNameChanged(e) {
        setLastName(e.target.value);
    };

    function onEmailAddressChanged(e) {
        setEmailAddress(e.target.value);
    }

    function createPracticeUser(event) {
        if (event !== undefined && event !== null) {
            event.preventDefault();
        }
        
        var payload = {
            firstName : firstName,
            lastName: lastName,
            emailAddress: emailAddress
        };
        onCreatePracticeUser(payload);
    };
    
    return isActive && <Modal onClose={() => onClose(false)}>
        <h3 className="modal__content-title textAlign--center margin__bottom--m">Add a Practice User</h3>
        <form onSubmit={createPracticeUser} id="practice-user-form" >
            <InputText className="margin__bottom--m" id="practice-user-first-name" label="First name" name="firstName" required={true} onChange={onFirstNameChanged}  />
            <InputText className="margin__bottom--m" id="practice-user-last-name" label="Last name" name="lastName" required={true} onChange={onLastNameChanged}  />
            <InputText className="margin__bottom--m" id="practice-user-email-address" label="Email address" name="emailAddress" required={true} onChange={onEmailAddressChanged}  />

            { error !== "" &&
                <p className="small input__error">{error}</p>
            }
            <div className="textAlign--right">
                <ButtonPrimary id="practice-user-button" type="submit" text="Save" className="button--regular"/>
            </div>
        </form>
    </Modal>
}