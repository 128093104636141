import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export default function Secure({ children }) {
    const { isLoading, user, isAuthenticated, loginWithRedirect } = useAuth0();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect();
        }
    }, [isLoading, user, isAuthenticated, loginWithRedirect]);

    return (
        <>
            {isAuthenticated && user && children}
            {(!isAuthenticated || !user) &&
                <div className={"toast__container toast--fadeIn"}>
                    <div className="toast">
                        <div className="toast__loader"></div>
                        <h2>Loading</h2>
                    </div>
                </div>
            }
        </>
    );
}
