import { AxiosResponse } from "axios";
import { httpGet, httpPost, httpPut } from "../modules/backend";

const root = 'manufacturers'

export interface IManufacturerListItem {
    name: string,
    id: string,
    isArchived: boolean
}

export interface IManufacturer {
    name: string
}

export interface IManufacturerEdit {
    name: string,
    id: string
}

export class ManufacturersApi {
    setLoading : any;
    setToastMessage: any;

    constructor(setloading: any, setToastMessage: any){
        this.setLoading = setloading;
        this.setToastMessage = setToastMessage;
    }

    async list(includeArchived: boolean, skip: number, take: number): Promise<AxiosResponse<IManufacturerListItem[]>> {
        this.setLoading(true);
        const result = await httpGet(ManufacturersApiEndpoints.list(includeArchived, skip, take));
        this.setLoading(false);
        return result;
    }

    async create(manufacturer: IManufacturer) : Promise<AxiosResponse<any>> {
        this.setToastMessage('Processing')
        this.setLoading(true);
        const result = await httpPost(ManufacturersApiEndpoints.create(), manufacturer);
        this.setLoading(false);
        return result;
    }

    async archive(manufacturerId: string) : Promise<AxiosResponse<any>> {
        this.setToastMessage('Processing')
        this.setLoading(true);
        const payload = {
            manufacturerId
        }
        const result = await httpPut(ManufacturersApiEndpoints.archive(), payload);
        this.setLoading(false);
        return result;
    }

    async get(id: string) : Promise<AxiosResponse<IManufacturer>> {
        this.setLoading(true);
        const result = await httpGet(ManufacturersApiEndpoints.get(id));
        this.setLoading(false);
        return result;
    }

    async edit(payload: IManufacturerEdit) : Promise<AxiosResponse<string>> {
        this.setToastMessage('Processing')
        this.setLoading(true);
        const result = await httpPut(ManufacturersApiEndpoints.edit(), payload);
        this.setLoading(false);
        return result;
    }
}

export class ManufacturersApiEndpoints {

    static list: any = (includeArchived: boolean, skip: number, take: number) => `${root}/list?includeArchived=${includeArchived}&skip=${skip}&take=${take}`;
    static create: any = () => root;
    static archive: any = () => `${root}/archive`;
    static get: any = (id: string) => `${root}?id=${id}`;
    static edit: any = () => `${root}`;
}