import { AxiosResponse } from "axios";
import {httpGet, httpPost, httpPut} from "../modules/backend";

const root = 'wholesalers'

export interface IWholesalerListItem {
    companyName: string,
    id: string,
    isArchived: boolean
}

export interface IWholesalerCreate {
    name: string
}

export interface IWholesaler {
    name: string,
    id: string,
    isArchived: boolean
}

export interface IWholesalerEdit {
    name: string,
    id: string
}

export default class WholesalersApi {
    setLoading : any;
    setToastMessage: any;

    constructor(setloading: any, setToastMessage: any){
        this.setLoading = setloading;
        this.setToastMessage = setToastMessage;
    }

    async list(includeArchived: boolean, skip: number, take: number) : Promise<AxiosResponse<IWholesalerListItem[]>>{
        this.setLoading(true);
        const result = await httpGet(WholesalersApiEndpoints.list(includeArchived, skip, take));
        this.setLoading(false);
        return result;
    }

    async create(wholesaler: IWholesalerCreate) : Promise<AxiosResponse<string>> {
        this.setToastMessage('Processing')
        this.setLoading(true);
        const result = await httpPost(WholesalersApiEndpoints.create(), wholesaler);
        this.setLoading(false);
        return result;
    }

    async get(id: string) : Promise<AxiosResponse<IWholesaler>> {
        this.setLoading(true);
        const result = await httpGet(WholesalersApiEndpoints.get(id));
        this.setLoading(false);
        return result;
    }

    async edit(payload: IWholesalerEdit) : Promise<AxiosResponse<string>> {
        this.setToastMessage('Processing')
        this.setLoading(true);
        const result = await httpPut(WholesalersApiEndpoints.edit(), payload);
        this.setLoading(false);
        return result;
    }

    async archive(wholesalerId: string) : Promise<AxiosResponse<any>> {
        this.setToastMessage('Processing')
        this.setLoading(true);
        const payload = {
            wholesalerId
        }
        const result = await httpPut(WholesalersApiEndpoints.archive(), payload);
        this.setLoading(false);
        return result;
    }
}

export class WholesalersApiEndpoints {

    static list: any = (includeArchived: boolean, skip: number, take: number) => `${root}/list?includeArchived=${includeArchived}&skip=${skip}&take=${take}`;
    static create: any = () => `${root}`;
    static get: any = (id: string) => `${root}?id=${id}`;
    static edit: any = () => `${root}`;
    static archive: any = () => `${root}/archive`;
}