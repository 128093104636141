import { Link } from "@reach/router";

export default function NavLink(props){
    return <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? "side-menu__item side-menu__item--active" : "side-menu__item"
      };
    }}
  />
}