import ButtonBack from "../../symbols/app/button/ButtonBack";
import {navigate} from "@reach/router";
import OAuth2Login from 'react-simple-oauth2-login';
import { useAuth0 } from "@auth0/auth0-react";
import * as Navigation from "../../modules/navigation";
import {useHttp} from "../../context/HttpProvider";
import ContentArea from "../../symbols/app/content/content-area";
import ReportSection from "../../symbols/report/report-section";
import React, {useEffect, useState} from "react";
import ButtonSecondaryWithIcon from "../../symbols/app/button/ButtonSecondaryWithIcon";
import WhiteCheckBox from '../../assets/images/white_checkbox.png';
import IconProcent from '../../assets/images/icon-procent.png';
import HttpStatusCodes from "../../modules/httpStatusCodes";
import Roles from '../../domain/auth/Roles';
import { Claims } from '../../modules/auth/Claims';

export default function AdminReportOverview({reportId}) {
    const {reportsApi, xeroTokensApi} = useHttp();
    const [report, setReport] = useState({});
    const [reportLoaded, setReportLoaded] = useState(false);
    const [hasXeroToken, setHasXeroToken] = useState(false);
    const [reportError, setReportError] = useState();
    const { user } = useAuth0();

    const role = user[Claims.Role][0];

    async function getReport() {
        var response = await reportsApi.getAdminReportBreakdown(reportId);
        if (response) {
            setReport(response);
            setReportLoaded(true);
        }
    };

    async function checkXeroToken() {
        var response = await xeroTokensApi.has();
        if (response) {
            setHasXeroToken(response.exists);
        }
    };

    useEffect(() => {
        if (reportsApi !== null && xeroTokensApi !== null) {
            getReport();
            checkXeroToken();
        }
    }, [reportsApi, xeroTokensApi]);

    async function reconcileReport() {
        setReportError();
        var response = await reportsApi.reconcile(reportId);
        if (response.status !== HttpStatusCodes.Ok) {
            setReportError(response.data[0]);
        } else {
            await getReport();
        }
    }

    async function issueRebates() {
        setReportError();
        var response = await reportsApi.issueRebate(reportId);
        if (response.status !== HttpStatusCodes.Ok) {
            setReportError(response.data[0]);
        } else {
            navigate(Navigation.reports.to);
        }
    };

    async function onLoginSuccess(loginResponse) {
        if (loginResponse) {
            var payload = {
                code : loginResponse.code
            };
            var response = await xeroTokensApi.create(payload);
            if (response.status === HttpStatusCodes.Created) {
                checkXeroToken();
            }
            else {
                setCreateXeroTokenError(response.data.Errors[0]);
            }
        }
    };

    async function onLoginFailure(loginResponse) {
        console.log(loginResponse);
    };

    return <ContentArea>
        <ButtonBack id="button-back-to-reports" onClick={() => navigate(Navigation.reports.to)} text="Back to Reports" className="margin__bottom--xs"/>
        <>
            <div className="grid-x grid-padding-y alignItems--center margin__bottom--s">
                <div className="cell small-12 medium-auto">
                    <h2 id="report-name">{report.name}</h2>
                </div>
            </div>

            { reportLoaded && report && report.sections &&
                <div className="margin__bottom--xl">
                    {report.sections.map((section, i) => <ReportSection reportSection={section} key={i}/>)}
                </div>
            }

            { reportError !== "" &&
                <p id="inputError" className="small input__error display--flex justifyContent--flex-end margin__bottom--s">{reportError}</p>
            }

            <div className="cell small-12 medium-shrink display--flex report__approve">
                { report.reconciled === false && 
                    <ButtonSecondaryWithIcon icon={<img className="margin__right--xs" src={WhiteCheckBox} />} id="btn-reconcile" text="Reconcile" className="button--large display--flex" onClick={reconcileReport}/>
                }

                { report.reconciled === true && report.rebated == false && hasXeroToken === true &&
                    <ButtonSecondaryWithIcon icon={<img className="margin__right--xs" src={IconProcent} />} id="btn-issue-rebates" text="Issue rebates" className="button--large display--flex" onClick={issueRebates}/>
                }

                { report.reconciled === true && report.rebated == false && hasXeroToken === false && role === Roles.AdminUser && 
                    <OAuth2Login
                        authorizationUrl={process.env.REACT_APP_XERO_AUTH_URL}
                        responseType={process.env.REACT_APP_XERO_RESPONSE_TYPE}
                        clientId={process.env.REACT_APP_XERO_CLIEND_ID}
                        redirectUri={process.env.REACT_APP_XERO_REDIRECT_URI}
                        scope={process.env.REACT_APP_XERO_SCOPE}
                        className="button button--secondary button--large"
                        buttonText="Login to Xero to Issue Rebates"
                        onSuccess={response => onLoginSuccess(response)}
                        onFailure={response => onLoginFailure(response)}
                        id="btn-xero-login" />
                }
            </div>

        </>
    </ContentArea>
}