import ForbiddenImage from "../../assets/images/403.png";
import ButtonPrimary from "../../symbols/app/button/ButtonPrimary";
import * as navigation from "../../modules/navigation";
import ContentArea from "../../symbols/app/content/content-area";

export default function ForbiddenError() {

    function goHome() {
        navigation.navigateHome();
    }

    return <ContentArea>
        <div className="grid-x grid-padding-x grid-padding-y justifyContent--center alignItems--center error-page__wrapper">
            <div className="cell small-6 xlarge-5 small-order-2 xlarge-order-1 show-for-xlarge">
                <img className=" error-page__image" src={ForbiddenImage}/>
            </div>
            <div className="cell small-12 xlarge-6 xlarge-offset-1 small-order-1 xlarge-order-2">
                <div className="grid-x error-page__text-wrapper">
                    <h1 className="cell small-12 medium-12 error-page__code margin__bottom--xs">403</h1>
                    <img className="error-page__image hide-for-xlarge margin__bottom--s" src={ForbiddenImage}/>
                    <h2 className="cell small-12 medium-12 margin__bottom--s error-page__text">Who goes there?</h2>
                    <p className="cell small-12 medium-12 margin__bottom--m error-page__text">We've changed the locks. The page you're trying to access is restricted.</p>
                    <div className="cell small-12 medium-6 xlarge-10 error-page__button">
                        <ButtonPrimary className="error-page__home-button" text="Go Home" onClick={goHome}/>
                    </div>
                </div>
            </div>
        </div>
    </ContentArea>
}