import React, { useState, useEffect } from "react";
import ContentArea from "../../symbols/app/content/content-area"
import { useHttp } from '../../context/HttpProvider';
import ButtonSecondary from '../../symbols/app/button/ButtonSecondary';
import ModalAddPracticeUser from "../../symbols/practices/ModalAddPracticeUser";
import HttpStatusCodes from '../../modules/httpStatusCodes';
import PracticeUsersList from "../../symbols/app/list/practice-users-list";
import InlineInputText from '../../symbols/app/form/InlineInputText';
import Tabs from "../../symbols/app/tabs/Tabs";
import Archive from '../../symbols/archiving/Archive';
import IconView from "../../assets/images/icon-view.png";
import {navigate} from "@reach/router";
import * as Navigation from "../../modules/navigation";
import { RenderByRole } from "../../symbols/app/auth/RenderByRole";
import Roles from "../../domain/auth/Roles";
import ReportsList from "../../symbols/app/list/reports-list";

export default function PracticeDetailPage({ id }) {
    const [hasPracticeLoaded, setHasPracticeLoaded] = useState(false);
    const [hasPracticeUsersLoaded, setHasPracticeUsersLoaded] = useState(false);
    const [showCreatePracticeUserModal, setShowCreatePracticeUserModal] = useState(false);
    const [showConfirmArchivePractice, setShowConfirmArchivePractice] = useState(false);
    const {practicesApi, practiceUsersApi, reportsApi, userRole} = useHttp();
    const [practice, setPractice] = useState();
    const [practiceUsers, setPracticeUsers] = useState();
    const [createPracticeUserError, setCreatePracticeUserError] = useState(null);    
    const [reportsTotalCount, setReportsTotalCount] = useState(0);
    const [reports, setReports] = useState();
    const [error, setError] = useState(false);
    const [archivePracticeError, setArchivePracticeError] = useState()
    const [totalCount, setTotalCount] = useState(0);
    const perPage = process.env.REACT_APP_PAGE_SIZE;
    const [showPracticeUserError, setShowPracticeUserError] = useState(false);

    async function getPractice() {
        var response = await practicesApi.getPracticeDetail(id);

        console.log(response)
        if (response) {
            setPractice(response);
            setHasPracticeLoaded(true);
        }
    };

    async function getReports(skip){
        var response = await reportsApi.listByPractice(id, skip, perPage);
        if (response) {
            setReports(response.reports);
            setReportsTotalCount(response.totalCount);
        }
    };

    async function getPracticeUsers(id, skipPage) {
        var response = await practiceUsersApi.getPracticeUsers(id, skipPage * perPage, perPage);
        if (response) {
            if(response.status !== undefined && response.status !== HttpStatusCodes.Ok) {
                setShowPracticeUserError(true);
            } else {
                setShowPracticeUserError(false);
                setPracticeUsers(response.practiceUsers);
                setTotalCount(response.totalCount);
                setHasPracticeUsersLoaded(true);
            }
        }
    };

    async function createPracticeUser(data) {
        var payload = {
            firstName: data.firstName,
            lastName: data.lastName,
            emailAddress: data.emailAddress,
            practiceId: id
        };

        let response = await practiceUsersApi.create(payload);
        if (response.status === HttpStatusCodes.Created) {
            setShowCreatePracticeUserModal(false);
            getPracticeUsers(id, 0);
        } else {
            setCreatePracticeUserError(response.data.Errors[0]);
        }
    };

    async function updatePracticeName(newValue) {
        var response = await practicesApi.updatePracticeName(newValue, id);
        if (response.status === HttpStatusCodes.Ok) {
            getPractice();
        } else {
            setError(response.data[0]);
        }
    };

    async function updateXeroContactId(newValue){
        var response = await practicesApi.updateXeroContactId(newValue, id);
        if (response.status === HttpStatusCodes.Ok) {
            getPractice();
        } else {
            setError(response.data[0]);
        }
    };

    async function archivePractice() {
        const response = await practicesApi.archive(id);
        if (response.status === HttpStatusCodes.Ok) {
            getPractice();
            setShowConfirmArchivePractice(false);
        } else {
            setArchivePracticeError(response.data[0]);
        }
    }

    function closeConfirmArchivePracticeModal() {
        setShowConfirmArchivePractice(false);
        setArchivePracticeError('');
    }

    function onArchivePracticeConfirmed() {
        archivePractice();
    }

    function navigateToList() {
        navigate(Navigation.practices.to);
    }

    useEffect(() => {
        if (practicesApi !== null && reportsApi !== null && practiceUsersApi !== null) {
            getPractice();
            getReports(0);
            getPracticeUsers(id, 0);
        }
    }, [practicesApi, reportsApi, practiceUsersApi]);

    return <ContentArea>
        <div className="detail-page__back-wrapper alignItems--center" onClick={navigateToList}>
            <RenderByRole>
                <img supportedrole={Roles.AdminUser} src={IconView} className="detail-page__back" />
                <p supportedrole={Roles.AdminUser}>Back to Practices</p>
            </RenderByRole>
        </div>
        {hasPracticeLoaded && hasPracticeUsersLoaded &&
            <>
                <div className="content-area__header">
                    <div className="content-area__header__left-container content-area__header__left-container--practice">
                        <RenderByRole>
                            <InlineInputText supportedrole={Roles.AdminUser} value={practice.companyName} saveFunction={updatePracticeName} error={error}>
                                <h1 id="practice-name-admin">{practice.companyName}</h1>
                            </InlineInputText>
                            <h1 id="practice-name" supportedrole={Roles.PracticeUser}>{practice.companyName}</h1>
                        </RenderByRole>
                        <RenderByRole>
                            <InlineInputText supportedrole={Roles.AdminUser} value={practice.xeroContactId} saveFunction={updateXeroContactId} error={error} title="Xero Contact Id">
                                <p id="practice-xero-contact-id">{practice.xeroContactId}</p>
                            </InlineInputText>
                        </RenderByRole>
                    </div>
                    <div className="content-area__header__right-container">
                        <RenderByRole>
                            <ButtonSecondary id="practice-user-add" supportedrole={Roles.AdminUser} text="New user" className="button--regular button--regular__add display--flex" onClick={() => setShowCreatePracticeUserModal(!showCreatePracticeUserModal)} />
                        </RenderByRole>
                    </div>
                </div>

                <Tabs labels={['Reports', 'Users']}>
                    <ReportsList reports={reports} getReports={getReports} totalCount={reportsTotalCount}  practiceId={id} />
                    <div>
                        {!showPracticeUserError && <PracticeUsersList practiceId={id} practiceUsers={practiceUsers} getPracticeUsers={getPracticeUsers} totalCount={totalCount} />}
                        {showPracticeUserError && <p>Oops, we could not retrieve the practice users, please try again.</p>}
                    </div>
                </Tabs>

                { practice && 
                    <Archive 
                        target={practice} type={'Practice'} 
                        isModalActive={showConfirmArchivePractice}
                        onClick={() => setShowConfirmArchivePractice(true)} 
                        onArchiveConfirmed={onArchivePracticeConfirmed}
                        onModalClose={closeConfirmArchivePracticeModal}
                        archiveError={archivePracticeError}
                    />
                }
            </>
        }
        <ModalAddPracticeUser isActive={showCreatePracticeUserModal} onClose={setShowCreatePracticeUserModal} onCreatePracticeUser={createPracticeUser} error={createPracticeUserError} />
    </ContentArea>
}