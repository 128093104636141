import React from "react";

export default function ReportTotals({totals}) {
    return<div>
        <div className="report__totals">
            <p className="report__totals__title margin__bottom--s">Totals</p>
            <div className="display--flex justifyContent--space-between report__totals__section">
                <p className="report__section__field report__section__name">Spent</p>
                <p id="spent-total" className="report__section__field">{totals.spentTotalFormatted}</p>
            </div>
            <div className="display--flex justifyContent--space-between report__totals__section">
                <p className="report__section__field report__section__name">Discount</p>
                <p id="discount-total" className="report__section__field">{totals.discountTotalFormatted}</p>
            </div>
            <div className="report__dotted-line"></div>
            <div className="display--flex justifyContent--space-between report__totals__section">
                <p className="report__section__field report__section__name">Management fee</p>
                <p id="management-fee-total"  className="report__section__field">{totals.managementFeeTotalFormatted}</p>
            </div>
        </div>
        
        <div className="display--flex justifyContent--space-between report__totals__section report__totals__rebate">
            <p className="report__totals__rebate__field report__section__name">Rebate</p>
            <p id="rebate-total" className="report__totals__rebate__field">{totals.rebateTotalFormatted}</p>
        </div>
    </div>
}