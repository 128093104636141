import PracticeUsersListItem from "./practice-users-list-item";
import Pagination from "./pagination";
import {useState} from "react";

export default function PracticeUsersList({ practiceId, getPracticeUsers, practiceUsers, totalCount }) {

    const perPage = process.env.REACT_APP_PAGE_SIZE;
    const [currentPage, setCurrentPage] = useState(0);

    function handlePageClick(data) {
        let selected = data.selected;
        setCurrentPage(selected)
        getPracticeUsers(practiceId, selected)
    };

    return <div className="cell small-12 practice-users-list">
        <div className="grid-x practice-users-list__head show-for-small hide-for-medium">
            <div className="cell small-12 medium-3">
                <p className="practice-users-list__field">Users</p>
            </div>
        </div>
        <div className="grid-x practice-users-list__head show-for-medium">
            <div className="cell small-12 medium-3">
                <p className="practice-users-list__field">Name</p>
            </div>
            <div className="cell small-12 medium-3">
                <p className="practice-users-list__field">Email</p>
            </div>
            <div className="cell small-12 medium-auto">
            </div>
        </div>

        { practiceUsers && practiceUsers.map((item, i) => <PracticeUsersListItem id={item.id} practiceId={practiceId} firstName={item.firstName} fullName={item.fullName} lastName={item.lastName} emailAddress={item.emailAddress} key={`practice-users-list-${i}`} getPracticeUsers={() => getPracticeUsers(practiceId, currentPage)} />) }

        {practiceUsers && totalCount > perPage &&
            <div className="grid-x">
                <div className="cell small-12 display--flex justifyContent--center margin__top--xl">
                    <Pagination currentPage={currentPage} pageSize={perPage} totalResults={totalCount} description={'practices'} onPageChanged={handlePageClick} />
                </div>
            </div>
        }
    </div>
}