import { useEffect } from "react";
import * as Navigation from "../../modules/navigation";
import { useAuth0 } from "@auth0/auth0-react";

export default function HomePage() {
    const { user, isAuthenticated } = useAuth0();

    useEffect(() => {
        if(user && isAuthenticated) {
            Navigation.navigateToLandingPage(user);
        }
    }, [user, isAuthenticated])

    return <></>
}