import ContentArea from "../app/content/content-area";
import { useReportWizard } from "./ReportWizardProvider";
import ModalMappingErrors from "../report-wizard/mapping/ModalMappingErrors";
import Steps from '../app/steps/Steps';

export default function ReportWizard({ stepLabels, children }) {

    const { smallContent, onBack, onContinue, mappingErrors, showMappingErrors, setShowMappingErrors, validToMoveForward } = useReportWizard();

    function hideMappingErrorsModal() {
        setShowMappingErrors(false);
    }

    return <ContentArea small={smallContent}>
        <div className="margin__bottom--xl">
            <h2 className="textAlign--center margin__bottom--s">Monthly Reports</h2>
            <Steps labels={stepLabels} validToMoveForward={validToMoveForward} onMoveForward={onContinue} onMoveBackward={onBack}>
                {children}
            </Steps>
        </div>
        <ModalMappingErrors isActive={showMappingErrors} title={'Mapping errors'} onOk={hideMappingErrorsModal} errors={mappingErrors} onClose={hideMappingErrorsModal} />
    </ContentArea>
}

