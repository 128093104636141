import React, { useState, useEffect } from "react";
import Modal from './Modal';
import InputText from '../form/FormInputText';
import ButtonPrimary from '../button/ButtonPrimary';

export default function ModalCreatePractice({ title, isActive, onClose, onSubmit, error, ...others }) {
    const [companyNameValue, setCompanyNameValue] = useState('');
    const [xeroContactIdValue, setXeroContactIdValue] = useState('');

    function onCompanyNameValueChanged(e) {
        setCompanyNameValue(e.target.value);
    };

    function onXeroContactIdValueChanged(e) {
        setXeroContactIdValue(e.target.value);
    };

    function submission(event) {
        if(event) {
            event.preventDefault();
        }
        onSubmit(companyNameValue, xeroContactIdValue);
    };

    return isActive && <Modal onClose={() => onClose(false)}>
        <h3 className="modal__content-title textAlign--center margin__bottom--m">{title}</h3>
        <form onSubmit={submission} id="frmModalSingleInput">
            <InputText id="modalCompanyNameInput" label="Company name" name="name" required={true} onChange={onCompanyNameValueChanged}  />
            <InputText id="modalXeroContactIdInput" className="margin__top--s" label="Xero Contact ID" name="name" required={true} onChange={onXeroContactIdValueChanged}  />
            { error !== "" &&
                <p id="inputError" className="small input__error">{error}</p>
            }
            <div className="textAlign--right">
                <ButtonPrimary type="submit" text="Save" className="button--regular margin__top--m"/>
            </div>
        </form>
    </Modal>
}