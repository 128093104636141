import React from "react"
import ProgressBar from "@ramonak/react-progress-bar";

export default function Progress({ percent, height, className, text, maxCompleted = 100 }) {
    return <div className={className}>
        <ProgressBar
            completed={percent}
            height={height}
            maxCompleted={maxCompleted}
            customLabel={text}
            bgColor={'#126EB7'}
            isLabelVisible={false}
            text={text}
        />
    </div>
}