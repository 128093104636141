import useDelayedLogout from "../../../../hooks/UseDelayedLogout";
import Modal from "../../modal/Modal";

export default function UserDeletedModal() {
    const [timeUntilLogout] = useDelayedLogout();

    return <Modal>
        <h1 className="textAlign--center margin__bottom--s error">Account Deleted</h1>
        <p className="textAlign--center margin__bottom--s">Your account has been deleted.</p>
        <div className="textAlign--center margin__bottom--s">You'll no longer have access to Vet Independent.</div>
        <p className="textAlign--center margin__top--xl margin__bottom--s"><strong>You'll be logged out in {timeUntilLogout} seconds.</strong></p>
    </Modal>
}