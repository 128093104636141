import React from "react";
import { navigate } from '@reach/router';
import * as Navigation from '../../modules/navigation';

export default function ReportItem({reportLine, reportId, practiceId = null}) {
    return<div>
        <div className="grid-x display--flex justifyContent--center report__section__row show-for-medium">
            <div className="cell medium-8">
                { reportId && reportLine.id ?
                    <p className="report__section__field report__section__name report__section__name--link" onClick={() => navigate(Navigation.reportProducts.navigate(reportId, reportLine.id, practiceId))}>{reportLine.name}</p>
                    :
                    <p className="report__section__field report__section__name">{reportLine.name}</p>
                }
            </div>
            <div className="cell medium-2">
                {reportLine.spent !== 0 && <p className="report__section__field textAlign--left">{reportLine.spentFormatted}</p>}
                {(reportLine.spent === null || reportLine.spent === 0) && <div className="report__section__field-empty"></div>}
            </div>
            <div className="cell medium-2 display--flex justifyContent--flex-end">
                {reportLine.rebate !== 0 && <p className="report__section__field textAlign--right">{reportLine.rebateFormatted}</p>}
                {(reportLine.rebate === null || reportLine.rebate === 0) && <div className="report__section__field-empty"></div>}
            </div>
        </div>

        <div className="grid-x alignItems--center show-for-small hide-for-medium">
            <div className="cell small-12 display--flex report-data-list__name textAlign--center margin__bottom--xs">
                <p className="report-data-list__field">{reportLine.name}</p>
            </div>
            <div className="cell small-12 grid-x report__item__mobile">
                <div className="cell small-6">
                    <p className="margin__top--xs margin__bottom--xs report-data-list__field textAlign--center">Spent</p>
                    <div className="cell small-12 display--flex report-data-list__upload">
                        {reportLine.spent !== 0 &&  <p className="report__section__field textAlign--center">{reportLine.spentFormatted}</p>}
                        {(reportLine.spent === null || reportLine.spent === 0) && <div className="report__section__field-empty"></div>}
                    </div>
                </div>

                <div className="cell small-6">
                    <p className="margin__top--xs margin__bottom--xs report-data-list__field justifyContent--center textAlign--center">Rebate</p>
                    <div className="cell small-12 display--flex justifyContent--center">
                        {reportLine.rebate !== 0 && <p className="report__section__field textAlign--center">{reportLine.rebateFormatted}</p>}
                        {(reportLine.rebate === null || reportLine.rebate === 0) && <div className="report__section__field-empty"></div>}
                    </div>
                </div>
            </div>
        </div>
    </div>
}