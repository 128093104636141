import React, { useState, useEffect } from "react";
import ContentArea from "../../symbols/app/content/content-area";
import { RenderByRole } from "../../symbols/app/auth/RenderByRole";
import ButtonSecondary from "../../symbols/app/button/ButtonSecondary";
import Roles from "../../domain/auth/Roles";
import BasicList from "../../symbols/app/list/basic-list";
import * as Navigation from "../../modules/navigation";
import Pagination from "../../symbols/app/list/pagination";
import { useHttp } from "../../context/HttpProvider";
import FormInputCheckbox from "../../symbols/app/form/FormInputCheckbox";
import HttpStatusCodes from "../../modules/httpStatusCodes";
import ModalSingleInput from "../../symbols/app/modal/ModalSingleInput";

export default function ManufacturersPage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [showCreateManufacturerModal, setShowCreateManufacturerModal] = useState(false);
    const [createManufacturerError, setCreateManufacturerError] = useState(null);
    const { manufacturersApi } = useHttp();
    const [manufacturers, setmanufacturers] = useState();
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [includeArchived, setIncludeArchived] = useState(false);
    const perPage = process.env.REACT_APP_PAGE_SIZE;
    const [showError, setShowError] = useState(false);

    async function getManufacturers(skip, archived) {
        var response = await manufacturersApi.list(archived != undefined ? archived : includeArchived, skip, perPage);
        if (response) {
            if(response.status !== undefined && response.status !== HttpStatusCodes.Ok) {
                setShowError(true);
            }
            else {
                setShowError(false);
                setmanufacturers(response.manufacturers);
                setTotalCount(response.totalCount);
                setHasLoaded(true);
            }
        }
    }

    async function handlePageClick(data) {
        let selected = data.selected;
        setCurrentPage(selected)
        await getManufacturers(selected * perPage)
    }

    async function changeIncludeArchived(include) {
        setIncludeArchived(include);
        await getManufacturers(0, include)
    }

    async function createManufacturer(name) {
        const payload = {
            name
        };

        let response = await manufacturersApi.create(payload);
        if (response.status === HttpStatusCodes.Created) {
            Navigation.manufacturerDetail.navigate(response.data.manufacturerId)
        } else {
            setCreateManufacturerError(response.data.Errors[0]);
        }
    }

    useEffect(() => {
        if (manufacturersApi != null) {
            getManufacturers(0, includeArchived);
        }
    }, [manufacturersApi]);

    return <ContentArea>
         <div className="content-area__header">
            <div className="content-area__header__left-container">
                <h1 className="margin__top--s">Manufacturers</h1>
            </div>
            <div className="content-area__header__right-container" >
                <RenderByRole>
                    <ButtonSecondary id="btnAddManufacturer" supportedrole={Roles.AdminUser} text="Add new manufacturer" className="button--regular button--regular__add" onClick={() => setShowCreateManufacturerModal(!showCreateManufacturerModal)} />
                </RenderByRole>
                <FormInputCheckbox id="chbIncludeArchived" label="Show archived" labelClassName="margin__left-right--xxs" value={includeArchived} onChange={() => changeIncludeArchived(!includeArchived)} />
            </div>           
            
        </div>
        {hasLoaded && !showError &&
            <>
                <BasicList items={manufacturers} navigatesTo={Navigation.manufacturerDetail.to} />
                {manufacturers && totalCount > perPage &&
                    <div className="grid-x">
                        <div className="cell small-12 display--flex justifyContent--center margin__top--xl">
                            <Pagination currentPage={currentPage} pageSize={perPage} totalResults={totalCount} description={'manufacturers'} onPageChanged={handlePageClick} />
                        </div>
                    </div>
                }
            </>
        }
        {
            showError &&
            <p>Oops, we could not retrieve the manufacturers, please try again.</p>
        }
        <ModalSingleInput title="Add A New Manufacturer" label="Name" isActive={showCreateManufacturerModal} onClose={setShowCreateManufacturerModal} onSubmit={createManufacturer} error={createManufacturerError}/>
    </ContentArea>
}