import { useState } from "react";
import ButtonPrimary from '../button/ButtonPrimary';
import ButtonBack from '../button/ButtonBack';

export default function Steps({ labels, children, validToMoveForward, onMoveForward, onMoveBackward }) {
    const [activeIndex, setActiveIndex] = useState(0);

    function back() {
        if (onMoveBackward) {
            onMoveBackward();
        }
        setActiveIndex(activeIndex - 1)
    }

    async function next() {
        if (onMoveForward) {
            const success = await onMoveForward();
            if (success) {
                setActiveIndex(activeIndex + 1)
            }
        } else {
            setActiveIndex(activeIndex + 1)
        }
    }

    return <>
        {
            labels.length === children.length && <div>
                <div id="steps" className="steps">
                    {
                        labels.map((label, i) => {
                            return <div className={activeIndex === i ? "steps__step active" : "steps__step"} key={i}>
                                <div className={activeIndex === i ? "steps__step__circle active" : "steps__step__circle"}><p>0{i + 1}</p></div>
                                <p className="steps__step__label">{label}</p>
                            </div>
                        })
                    }
                </div>

                <div className="steps__content">
                    {children.map((child, i) => {
                        return <div key={`content-${i}`}>
                            {
                                activeIndex === i && child
                            }
                        </div>
                    })}
                </div>
                <div className="grid-x steps__buttons">
                    {activeIndex > 0 &&
                        <ButtonBack id="steps__btn--back" onClick={back} text="Back" className="button--regular" />
                    }
                    {activeIndex < children.length &&
                        <ButtonPrimary id="steps__btn--continue" className="button--regular" onClick={next} text="Continue" disabled={!validToMoveForward} />
                    }
                </div>
            </div>
        }
        {
            labels.length !== children.length && <p>The length of the labels and children do not match. Please check your data.</p>
        }
    </>
}
