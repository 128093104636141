import React, { useState, useEffect } from "react";
import ContentArea from "../../symbols/app/content/content-area";
import { useHttp } from "../../context/HttpProvider";
import ButtonBack from "../../symbols/app/button/ButtonBack";
import {navigate} from "@reach/router";
import * as Navigation from "../../modules/navigation";
import ButtonSecondary from '../../symbols/app/button/ButtonSecondary';
import ReportsDataList from "../../symbols/app/list/report-data-list";

export default function ReportDetailPage({id}) {
    
    const { reportsApi } = useHttp();
    const [report, setReport] = useState({});
    const [reportLoaded, setReportLoaded] = useState(false);
    
    async function getReport() {
        var response = await reportsApi.get(id);
        if(response) {
            setReport(response);
            setReportLoaded(true);
        }
    }

    function navigateToList() {
        navigate(Navigation.reports.to);
    }

    useEffect(() => {
        if(reportsApi != null) {
            getReport();
        }
    }, [reportsApi])

    return <ContentArea>
        <ButtonBack id="button-back-to-reports" onClick={navigateToList} text="Back to Reports" className="margin__bottom--xs"/>

        { reportLoaded &&
            <>
                <div className="grid-x grid-padding-y alignItems--center">
                    <div className="cell small-12 medium-auto">
                        <h2 id="report-name">{report.name}</h2>
                    </div>
                    <div className="cell small-12 medium-shrink">
                        <ButtonSecondary id="reports-view-rebates" text="View rebates" className="button--large display--flex" onClick={() => Navigation.adminReport.navigate(id)} />
                    </div>
                </div>

                <ReportsDataList type="Manufacturers" reportId={id} items={report.manufacturerReportUploads} totalFormatted={report.manufacturerTotalFormatted}></ReportsDataList>
                <ReportsDataList type="Wholesalers" reportId={id} items={report.wholesalerReportUploads} totalFormatted={report.wholesalerTotalFormatted}></ReportsDataList>
            </>
        }
    </ContentArea>
}