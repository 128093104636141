import ContentArea from "../../symbols/app/content/content-area";
import {RenderByRole} from "../../symbols/app/auth/RenderByRole";
import Roles from "../../domain/auth/Roles";
import InlineInputText from "../../symbols/app/form/InlineInputText";
import {useEffect, useState} from "react";
import {useHttp} from "../../context/HttpProvider";
import Tabs from "../../symbols/app/tabs/Tabs";
import IconView from "../../assets/images/icon-view.png";
import {navigate} from "@reach/router";
import * as Navigation from "../../modules/navigation";
import HttpStatusCodes from "../../modules/httpStatusCodes";
import Archive from "../../symbols/archiving/Archive";
import ReportsList from "../../symbols/app/list/reports-list";

export default function WholesalerDetailPage({id}) {
    const [wholesalerLoaded, setWholesalerLoaded] = useState(false);
    const {wholesalersApi, reportsApi } = useHttp();
    const [wholesaler, setWholesaler] = useState({});
    const [reportsTotalCount, setReportsTotalCount] = useState(0);
    const [reports, setReports] = useState();
    const [error, setError] = useState(false);
    const [showConfirmArchiveWholesaler, setShowConfirmArchiveWholesaler] = useState(false);
    const [archiveWholesalerError, setArchiveWholesalerError] = useState();
    const perPage = process.env.REACT_APP_PAGE_SIZE;

    async function getWholesaler() {
        var response = await wholesalersApi.get(id);
        if(response) {
            setWholesaler(response);
            setWholesalerLoaded(true);
        }
    }

    async function getReports(skip){
        var response = await reportsApi.listByWholesaler(id, skip, perPage);
        if (response) {
            setReports(response.reports);
            setReportsTotalCount(response.totalCount);
        }
    };

    async function updateWholesalerName(value) {
        var payload = {
            id: id,
            name: value
        };
        var response = await wholesalersApi.edit(payload);
        if (response.status === HttpStatusCodes.Ok) {
            getWholesaler();
        } else {
            setError(response.data[0]);
        }
    }

    async function archiveWholesaler() {
        const response = await wholesalersApi.archive(id);
        if (response.status === HttpStatusCodes.Ok) {
            getWholesaler();
            setShowConfirmArchiveWholesaler(false);
        } else {
            setArchiveWholesalerError(response.data[0]);
        }
    }

    function closeConfirmArchiveWholesalerModal() {
        setShowConfirmArchiveWholesaler(false);
        setArchiveWholesalerError('');
    }

    function onArchiveWholesalerConfirmed() {
        archiveWholesaler();
    }

    function navigateToList() {
        navigate(Navigation.wholesalers.to);
    }

    useEffect(() => {
        if(wholesalersApi != null && reportsApi !== null) {
            getWholesaler();
            getReports(0);
        }
    }, [wholesalersApi])

    return <ContentArea>
        <div className="detail-page__back-wrapper" onClick={navigateToList}>
            <img src={IconView} className="detail-page__back" />
            <p>Back to Wholesalers</p>
        </div>
        { wholesalerLoaded &&
            <>
                <div className="grid-x grid-padding-y margin__bottom--m alignItems--center">
                    <div className="cell small-12 medium-auto">
                        <RenderByRole key={wholesaler.name}>
                            <h1 id="wholesaler-name" supportedrole={Roles.PracticeUser}>{wholesaler.name}</h1>
                            <InlineInputText supportedrole={Roles.AdminUser} value={wholesaler.name} saveFunction={updateWholesalerName} error={error}>
                                <h1 id="wholesaler-name-admin">{wholesaler.name}</h1>
                            </InlineInputText>
                        </RenderByRole>
                    </div>
                </div>

                <Tabs labels={['Reports', 'Products']}>
                <ReportsList reports={reports} getReports={getReports} totalCount={reportsTotalCount} />
                    <p>THIS IS THE PRODUCTS TAB</p>
                </Tabs>
                {wholesaler && <Archive target={wholesaler} type={'Wholesaler'} 
                    onClick={() => setShowConfirmArchiveWholesaler(true)}
                    isModalActive={showConfirmArchiveWholesaler}
                    onArchiveConfirmed={onArchiveWholesalerConfirmed}
                    onModalClose={closeConfirmArchiveWholesalerModal}
                    archiveError={archiveWholesalerError}
                />}
            </>
        }
    </ContentArea>
}