export default function ArchiveInfo({ target }) {
    return <>
        {target?.archived &&
            <div className="grid-x archive__button-holder archive__info">
                <div id='info-archived' className="cell small-12 display--flex justifyContent--center">
                    <p>Archived</p>
                </div>
            </div>
        }
    </>
}