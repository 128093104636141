import { httpGet, httpPost } from "../modules/backend";

const root = 'auth'

export interface IAuthConfig
{
    domain: string, 
    clientId: string,
    audience: string,
    scope: string
}

export default class AuthApi{
    setLoading : any;
    setToastMessage: any;

    constructor(setloading: any, setToastMessage: any){
        this.setLoading = setloading;
        this.setToastMessage = setToastMessage;
    }

    async ensureCreated(authId: string) : Promise<string> {
        this.setToastMessage('Processing')
        this.setLoading(true);
        const result = await httpPost(`${root}/ensure-created`, { authId });
        this.setLoading(false);
        return result.data;
    }
}

export async function getConfig() : Promise<IAuthConfig> {
    return await httpGet(`${root}/config`);
}