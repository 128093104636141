import Axios from "axios";

export async function httpGet(url) {
    try{
    const result = await Axios.get(`${process.env.REACT_APP_API_URL}/api/${url}`, {
        headers: {
            'Authorization': `bearer ${localStorage.getItem(process.env.ACCESS_TOKEN)}`,
            'Content-Type': 'application/json',
            Pragma: 'no-cache'
        }
    });
    return result.data;
    } catch(error) {
        return error.response;
    }
}

export async function httpGetUnauthorized(url) {
    const result = await Axios.get(`${process.env.REACT_APP_API_URL}/api/${url}`, {
        headers: {
            'Content-Type': 'application/json',
            Pragma: 'no-cache'
        }
    });
    return result.data;
}

export async function httpPut(url, values) {
    try {
        return await Axios.put(`${process.env.REACT_APP_API_URL}/api/${url}`, values, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem(process.env.ACCESS_TOKEN)}`,
                'Content-Type': 'application/json',
                Pragma: 'no-cache'
            }
        });
    } catch(error) {
        return error.response;
    }
}

export async function httpPost(url, values, additionalConfig = {}) {
    try {

        const config = {
            headers: {
                'Authorization': `bearer ${localStorage.getItem(process.env.ACCESS_TOKEN)}`,
                'Content-Type': 'application/json',
                Pragma: 'no-cache'
            }
        };

        for (const [key, value] of Object.entries(additionalConfig)) {
            config[key] = value;
        }

        return await Axios.post(`${process.env.REACT_APP_API_URL}/api/${url}`, values, config)
    } catch(error) {
        return error.response;
    }
}

export async function httpDelete(url, values) {
    return await Axios.delete(`${process.env.REACT_APP_API_URL}/api/${url}`, {
        headers: {
            'Authorization': `bearer ${localStorage.getItem(process.env.ACCESS_TOKEN)}`,
            'Content-Type': 'application/json',
            Pragma: 'no-cache',
        },
        data: values
    })
}