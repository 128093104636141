import React from "react";
import ReactPaginate from 'react-paginate';
import * as Window from '../../../modules/window';

export default function Pagination({ onPageChanged, currentPage, pageSize, totalResults, description }) {

    const currentPageNumber = Number(currentPage);
    const pageSizeNumber = Number(pageSize);
    const totalResultsNumber = Number(totalResults);

    const rangeStart = (currentPageNumber * pageSizeNumber) + 1;
    let rangeEnd = (rangeStart + pageSizeNumber) - 1;
    
    if (rangeEnd > totalResultsNumber) {
        rangeEnd = totalResultsNumber
    }
    
    const displayRange = Window.isMobile() ? 2 : 5;

    return <div>
        <div className="cell small-12">
            <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'....'}
                breakClassName={'break-me'}
                pageCount={Math.ceil(totalResultsNumber / pageSizeNumber)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={displayRange}
                onPageChange={onPageChanged}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
            />
        </div>
        <div className="textAlign--center padding__top--xs padding__bottom--xs">
            <p className="pagination__info-text" id="pagination-info">{rangeStart} - {rangeEnd} of {totalResults} {description}</p>
        </div>
    </div>

}