import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Claims } from '../../../modules/auth/Claims';

export function RenderByRole({ children, components }) {

    const [component, setComponent] = useState(<></>);
    const { user, isAuthenticated } = useAuth0();

    useEffect(() => {
        if (isAuthenticated) {
            const componentByRole = getByRole();
            setComponent(componentByRole);
        }
    }, [user, isAuthenticated])

    function getByRole() {
        const role = user[Claims.Role][0];
        if(components && role in components) {
            return components[role];
        }
        if (role) {
            let toRender = undefined
            let found = false;
            React.Children.forEach(children, element => {    
                if (React.isValidElement(element) && !found && element.props.supportedrole) {
                    let foundByRole = element.props.supportedrole === role;
                    if (foundByRole) {
                        toRender = element;
                        found = true;
                    }
                }
            })
            if (toRender) {
                return toRender;
            }
            else {
                return component;
            }
        }
        else {
            return component;
        }
    }

    return component;
}