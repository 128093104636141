import Modal from "../app/modal/Modal";
import InputText from "../app/form/FormInputText";
import ButtonPrimary from "../app/button/ButtonPrimary";
import {useState} from "react";

export default function ModalAddProduct({manufacturerId, isActive, onClose, onSubmit, error}) {
    const [name, setName] = useState('');
    const [discount, setDiscount] = useState(0);

    function onNameChanged(e) {
        setName(e.target.value);
    };

    function onDiscountChanged(e) {
        setDiscount(e.target.value);
    }

    function submit(event) {
        if(event) {
            event.preventDefault();
        }
        var payload = {
            name: name,
            discount: parseFloat(discount),
            manufacturerId: manufacturerId
        }
        onSubmit(payload);
    }

    return isActive && <Modal onClose={() => onClose(false)}>
        <h3 className="modal__content-title textAlign--center margin__bottom--m">Add Product</h3>
        <form onSubmit={submit} id="frmModalAddProduct">
            <InputText className="margin__bottom--m" label="Product name" name="name" id="txtProductName" required={true} onChange={onNameChanged}  />
            <InputText label="Discount (%)" name="discount" type="number" step=".01" id="txtProductDiscount" required={true} onChange={onDiscountChanged} className="margin__bottom--xs"/>
            { error !== "" &&
                <p id="inputError" className="small input__error">{error}</p>
            }
            <div className="textAlign--right">
                <ButtonPrimary id="btnModalAddProductSave" type="submit" text="Save" className="button--regular margin__top--m"/>
            </div>
        </form>
    </Modal>
}