import { AxiosResponse } from "axios";
import {httpDelete, httpGet, httpPost, httpPut} from "../modules/backend";

const root = 'practice-users';

export interface IPracticeUser {
    firstName: string, 
    lastName: string,
    emailAddress: string,
    practiceId: string
};

export interface IPracticeUserUpdate {
    firstName: string, 
    lastName: string,
    emailAddress: string,
    id: string
};

export interface IPracticeUsersListItem {
    id: string,
    name: string,
    email: string
};

export interface IDeletePracticeUser {
    userId: string,
    practiceId: string
}


export class PracticeUsersApi {
    setLoading : any;
    setToastMessage: any;

    constructor(setloading: any, setToastMessage: any){
        this.setLoading = setloading;
        this.setToastMessage = setToastMessage;
    }
    
    async create(practiceUser: IPracticeUser) : Promise<AxiosResponse<IPracticeUser>> {
        this.setToastMessage('Processing')
        this.setLoading(true);
        const result = await httpPost(PracticeUsersApiEndpoints.createPracticeUser, practiceUser);
        this.setLoading(false);
        return result;
    };

    async getPracticeUsers(practiceId: string, skip: number, take: number): Promise<AxiosResponse<IPracticeUsersListItem[]>> {
        this.setLoading(true);
        var response = await httpGet(PracticeUsersApiEndpoints.listPracticeUsers(practiceId, skip, take))
        this.setLoading(false);
        return response;
    };

    async delete(practiceUser: IDeletePracticeUser) : Promise<AxiosResponse<string>> {
        this.setToastMessage('Processing')
        this.setLoading(true);
        var response = await httpDelete(PracticeUsersApiEndpoints.delete(), practiceUser);
        this.setLoading(false);
        return response;
    }

    async edit(practiceUserUpdate: IPracticeUserUpdate) : Promise<AxiosResponse<string>> {
        this.setToastMessage('Processing')
        this.setLoading(true);
        const result = await httpPost(PracticeUsersApiEndpoints.edit(), practiceUserUpdate);
        this.setLoading(false);
        return result;
    };
};

export class PracticeUsersApiEndpoints {
    static createPracticeUser = root;
    static listPracticeUsers: any = (practiceId: string, skip: number, take: number) => `${root}?practiceId=${practiceId}&skip=${skip}&take=${take}`;
    static delete: any = () => root;
    static edit:  any = () => `${root}/edit`;
};