import DropDownArrow from '../../../assets/images/drop-down.svg';
import React from 'react';

export default function FormSelect({className, label, name, options, isValid, ...other}) {
	return <div className={`${className ? className : ''}`}>
		{label && <label className={`input-select__label`} htmlFor={name}>
			{label}
		</label>}
		<div className={`input-select__wrapper`}>
			<select className={`input-select__select ${isValid ? '' : 'input-select__select--error'}`} name={name} {...other}>
				{options ? options.map((option) => <option value={option.value} key={option.text}>{option.text}</option>) : ''}
			</select>
			<img className={`input-select__icon`} src={DropDownArrow} alt="Down arrow"/>
		</div>
	</div>
}
