import React, { useState, useEffect } from 'react';
import InputText from './FormInputText';
import IconEdit from '../../../assets/images/icon-edit.svg';
import IconSave from '../../../assets/images/icon-save.svg';

export default function InlineInputText({className, title, error, value, saveFunction, children, ...other}) {
    const [showEdit, setShowEdit] = useState(false);
    const [localValue, setLocalValue] = useState('');

    function toggleShowEdit() {
        setShowEdit(!showEdit);
    }
    function onTextChange(e) {
        setLocalValue(e.target.value);
    }
    function updateValue() {
        saveFunction(localValue);
        setShowEdit(!showEdit);
    }

    useEffect(() => {
        setLocalValue(value);
	}, []);

    return <div className={`${className ? className : ''} `}>
                {title &&
                    <p className="inline-edit__title">{title}</p>
                }
                
                <div className="display--flex">
                    <div className={`inline-edit--display-text ${showEdit ? 'display--hidden' : ''}`}>
                        {children}
                    </div>
                    <InputText id="txtInline" className={`margin__right--s ${showEdit ? '' : 'display--hidden'}`} value={localValue} onChange={onTextChange}></InputText>
                    <img id="btnEdit" src={IconEdit} alt="Edit icon" className={`icon margin__top--xxs ${showEdit ? 'display--hidden' : ''}`} onClick={toggleShowEdit} />
                    <img id="btnSave" src={IconSave} alt="Save icon" className={`icon margin__top--xxs ${!showEdit ? 'display--hidden' : ''}`} onClick={updateValue} />
                </div>    
                { error !== "" &&
                    <p className="small input__error">{error}</p>
                }   
            </div>  
}