import React, { useState, useEffect } from "react";
import ContentArea from "../../symbols/app/content/content-area"
import BasicList from "../../symbols/app/list/basic-list"
import Pagination from "../../symbols/app/list/pagination";
import ButtonSecondary from '../../symbols/app/button/ButtonSecondary';
import ModalCreatePractice from "../../symbols/app/modal/ModalCreatePractice";
import { useHttp } from '../../context/HttpProvider';
import httpStatusCodes from '../../modules/httpStatusCodes';
import { RenderByRole } from "../../symbols/app/auth/RenderByRole";
import Roles from '../../domain/auth/Roles';
import * as Navigation from '../../modules/navigation';
import {navigate} from '@reach/router';
import FormInputCheckbox from '../../symbols/app/form/FormInputCheckbox';

export default function PracticesPage() {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [showCreatePracticeModal, setShowCreatePracticeModal] = useState(false);
    const [createPracticeError, setCreatePracticeError] = useState(null);
    const { practicesApi } = useHttp();
    const [practices, setPractices] = useState();
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const perPage = process.env.REACT_APP_PAGE_SIZE;
    const [includeArchived, setIncludeArchived] = useState(false);

    async function createPractice(companyNameValue, xeroContactIdValue){
        var payload = {
            companyName : companyNameValue,
            xeroContactId: xeroContactIdValue
        };

        let response = await practicesApi.create(payload);
        if (response.status === httpStatusCodes.Created) {
            navigate(`${Navigation.practiceDetail.to}${response.data.practiceId}`);
        } else {
            setCreatePracticeError(response.data.Errors[0]);
        }
    }

    async function getPractices(skip, includeArchived){
        var response = await practicesApi.getPractices(includeArchived, skip, perPage);
        if (response) {
            setPractices(response.practices);
            setTotalCount(response.totalCount);
            setHasLoaded(true);
        }
    }

    function handlePageClick(data){
        let selected = data.selected;
        setCurrentPage(selected)
        getPractices(selected * perPage, includeArchived)
    }

    async function changeIncludeArchived(include) {
        setIncludeArchived(include);
        await getPractices(0, include)
    }

    useEffect(() => {
        if(practicesApi!=null){
            getPractices(0, includeArchived);
        }
    }, [practicesApi]);


    return <ContentArea>
        <div className="content-area__header">
            <div className="content-area__header__left-container">
                <h1 className="margin__top--s">Practices</h1>
            </div>
            <div className="content-area__header__right-container">
            <RenderByRole>
                    <ButtonSecondary id="btnAddPractice" supportedrole={Roles.AdminUser} text="Add new practice" className="button--regular button--regular__add" onClick={() => setShowCreatePracticeModal(!showCreatePracticeModal)}/>
                </RenderByRole>
            <FormInputCheckbox  id="checkboxIncludeArchived" label="Show archived" labelClassName="margin__left-right--xxs" value={includeArchived} onChange={() => changeIncludeArchived(!includeArchived)}/>
            </div>
            
        </div>
        {hasLoaded &&
            <>
                <BasicList items={practices} navigatesTo={`${Navigation.practiceDetail.to}`} />
                {practices && totalCount > perPage &&
                    <div className="grid-x">
                        <div className="cell small-12 display--flex justifyContent--center margin__top--xl">
                            <Pagination currentPage={currentPage} pageSize={perPage} totalResults={totalCount} description={'practices'} onPageChanged={handlePageClick}/>
                        </div>
                    </div>
                }
            </>
        }
        <ModalCreatePractice title="Add a New Practice" isActive={showCreatePracticeModal} onClose={setShowCreatePracticeModal} onSubmit={createPractice} error={createPracticeError} />
    </ContentArea>

}