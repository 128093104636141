import { AxiosResponse } from "axios";
import {httpGet, httpPost, httpPut} from "../modules/backend";

const root = 'practices'

export interface IPractice
{
    companyName: string, 
    xeroCompanyId: string
}

export interface IPracticeListItem {
    companyName: string,
    id: string,
    isArchived: boolean
}

export default class PracticesApi {
    setLoading : any;
    setToastMessage: any;

    constructor(setloading: any, setToastMessage: any){
        this.setLoading = setloading;
        this.setToastMessage = setToastMessage;
    }
    
    async create(practice: IPractice) : Promise<AxiosResponse<IPractice>> {
        this.setLoading(true);
        const result = await httpPost(PracticesApiEndpoints.create(), practice);
        this.setLoading(false);
        return result;
    }

    async getPractices(includeArchived: boolean, skip:number, take:number) : Promise<AxiosResponse<IPracticeListItem[]>> {
        this.setLoading(true);
        var response = await httpGet(PracticesApiEndpoints.listPractices(includeArchived, skip, take))
        this.setLoading(false);
        return response;
    };

    async getPracticeDetail(id: string) : Promise<string> {
        this.setLoading(true);
        var response = await httpGet(PracticesApiEndpoints.getPractice(id));
        this.setLoading(false);
        return response;
    };

    async updatePracticeName(companyName: string, practiceId: string) : Promise<AxiosResponse<any>> {
        this.setLoading(true);
        const payload = {
            companyName,
            practiceId,
        }
        const result = await httpPut(PracticesApiEndpoints.updateName(), payload);
        this.setLoading(false);
        return result;
    };

    async updateXeroContactId(xeroContactId: string, practiceId: string) : Promise<AxiosResponse<any>> {
        this.setLoading(true);
        const payload = {
            xeroContactId,
            practiceId,
        }
        const result = await httpPut(PracticesApiEndpoints.updateXeroContactId(), payload);
        this.setLoading(false);
        return result;
    };

    async archive(practiceId: string) : Promise<AxiosResponse<any>> {
        this.setLoading(true);
        const payload = {
            practiceId,
        }
        const result = await httpPut(PracticesApiEndpoints.archive(), payload);
        this.setLoading(false);
        return result;
    }    
}

export class PracticesApiEndpoints {
    
    static listPractices: any = (includeArchived: boolean, skip: number, take: number) => `${root}?includeArchived=${includeArchived}&skip=${skip}&take=${take}`;

    static getPractice: any = (id: string) => `${root}/practiceId?Id=${id}`;

    static create: any = () => `${root}`;

    static archive: any = () => `${root}/archive`

    static updateName: any = () => `${root}/update-name`;

    static updateXeroContactId: any = () => `${root}/update-xero-contact-id`;
}
