import FormSelect from "../../app/form/FormSelect";

export default function ValueMappingListItem({ mapping, options, updateMapping, validationLookup }) {

    const selectOptions = options.map((o, i) => {
        return {
            value: o,
            text: o
        }
    })
    const allOptions = [ { value: null, text: 'Please select' }, ...selectOptions ]

    return <div className="mapping__list__item grid-x">
        <div className="cell small-12 medium-6">
            <p className="mapping__list__item__display-value">{mapping?.name}</p>
        </div>
        <div className="cell small-12 medium-6">
            <FormSelect value={mapping?.mappingValue ?? ''}
                isValid={validationLookup ? validationLookup[mapping.id] : true}
                className="mapping__list__item__picker" 
                name={'Mapping option'} 
                onChange={(e) => updateMapping(mapping, e.target.value)}
                options={allOptions} />
        </div>
    </div>
}