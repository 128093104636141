import ButtonBack from "../../symbols/app/button/ButtonBack";
import {navigate} from "@reach/router";
import * as Navigation from "../../modules/navigation";
import {useHttp} from "../../context/HttpProvider";
import ContentArea from "../../symbols/app/content/content-area";
import ReportSection from "../../symbols/report/report-section";
import React, {useEffect, useState} from "react";

export default function ReportProductsPage({reportId, manufacturerId, practiceId}) {
    const {productsApi} = useHttp();
    const [products, setProducts] = useState({});
    const [productsLoaded, setProductsLoaded] = useState(false);
    const [productsError, setProductsError] = useState();

    async function getProducts() {
        var response = await productsApi.getProductsBreakdownByReportAndManufacturer(reportId, manufacturerId, practiceId);
        if (response) {
            setProducts(response);
            setProductsLoaded(true);
        }
    };

    useEffect(() => {
        if (productsApi !== null) {
            getProducts();
        }
    }, [productsApi]);

    return <ContentArea>
        <ButtonBack id="button-back-to-report" onClick={() => navigate(Navigation.practiceReport.navigate(reportId, products.practiceId))} text="Back to Report" className="margin__bottom--xs"/>
        <>
            <div className="grid-x grid-padding-y alignItems--center margin__bottom--s">
                <div className="cell small-12 medium-auto">
                    <h2 id="manufacturer-name">{products.manufacturerName}</h2>
                </div>
            </div>

            { productsLoaded && products && products.sections &&
                <div className="margin__bottom--xl">
                    {products.sections.map((section, i) => <ReportSection reportSection={section} key={i} practiceId={practiceId} />)}
                </div>
            }

            { productsError !== "" &&
                <p id="inputError" className="small input__error display--flex justifyContent--flex-end margin__bottom--s">{productsError}</p>
            }
        </>
    </ContentArea>
}