import { AxiosResponse } from "axios";
import { ReportType } from "../domain/reports/ReportType";
import { httpPost, httpGet } from "../modules/backend";

const root = 'reports';

export interface IReport
{
    name: string, 
}

export interface IReportsListItem {
    id: string,
    name: string
};

export default class ReportsApi {
    setLoading : any;
    setToastMessage: any;

    constructor(setloading: any, setToastMessage: any){
        this.setLoading = setloading;
        this.setToastMessage = setToastMessage;
    }
    
    async create(report: IReport) : Promise<AxiosResponse<IReport>> {
        this.setToastMessage('Processing')
        this.setLoading(true);
        const result = await httpPost(ReportsApiEndpoints.create(), report);
        this.setLoading(false);
        return result;
    }

    async get(id: string) : Promise<AxiosResponse<IReport>> {
        this.setLoading(true);
        const result = await httpGet(ReportsApiEndpoints.get(id));
        this.setLoading(false);
        return result;
    }

    async getReportUpload(id: string, reportType: typeof ReportType) {
        this.setLoading(true);
        const result = await httpGet(ReportsApiEndpoints.getUpload(id, reportType));
        this.setLoading(false);
        return result;
    }

    async getReports(skip: number, take: number): Promise<AxiosResponse<IReportsListItem[]>> {
        this.setLoading(true);
        var response = await httpGet(ReportsApiEndpoints.listReports(skip, take));
        this.setLoading(false);
        return response;
    };

    async uploadSpreadsheet(reportUploadId: string, reportType: typeof ReportType, file: any, onUploadProgress: any) : Promise<string> {
        this.setToastMessage('Processing')
        const formData = new FormData();
        formData.append("reportUploadId", reportUploadId);
        formData.append("reportType", reportType.toString());
        formData.append("file", file);
        const result = await httpPost(ReportsApiEndpoints.uploadSpreadsheet(), formData, { onUploadProgress });
        return result;
    };

    async listByManufacturer(manufacturerId: string, skip: number, take: number): Promise<AxiosResponse<IReportsListItem[]>> {
        this.setLoading(true);
        var response = await httpGet(ReportsApiEndpoints.listByManufacturer(manufacturerId, skip, take));
        this.setLoading(false);
        return response;
    };

    async listByWholesaler(wholesalerid: string, skip: number, take: number): Promise<AxiosResponse<IReportsListItem[]>> {
        this.setLoading(true);
        var response = await httpGet(ReportsApiEndpoints.listByWholesaler(wholesalerid, skip, take));
        this.setLoading(false);
        return response;
    };

    async listByPractice(practiceid: string, skip: number, take: number): Promise<AxiosResponse<IReportsListItem[]>> {
        this.setLoading(true);
        var response = await httpGet(ReportsApiEndpoints.listByPractice(practiceid, skip, take));
        this.setLoading(false);
        return response;
    };

    async reconcile(reportId: string): Promise<any> {
        this.setToastMessage('Processing')
        this.setLoading(true);
        var response = await httpPost(ReportsApiEndpoints.reconcile(), {reportId: reportId});
        this.setLoading(false);
        return response;
    }

    async getAdminReportBreakdown(reportId: string): Promise<AxiosResponse<IReportsListItem[]>> {
        this.setLoading(true);
        var response = await httpGet(ReportsApiEndpoints.getAdminReportBreakdown(reportId));
        this.setLoading(false);
        return response;
    };

    async getPracticeReportBreakdown(reportId: string, practiceId: string) {
        this.setLoading(true);
        const result = await httpGet(ReportsApiEndpoints.getPracticeReportBreakdown(reportId, practiceId));
        this.setLoading(false);
        return result;
    };

    async issueRebate(reportId: string): Promise<any> {
        this.setToastMessage('Processing')
        this.setLoading(true);
        var response = await httpPost(ReportsApiEndpoints.issueRebate(), {reportId: reportId});
        this.setLoading(false);
        return response;
    };
}

export class ReportsApiEndpoints {
    static create: any = () => `${root}`;
    static get: any = (id: string) => `${root}?id=${id}`;
    static getUpload: any = (id: string, type: string) => `${root}/get-upload?id=${id}&reportType=${type}`;
    static listReports: any = (skip: number, take: number) => `${root}/list?skip=${skip}&take=${take}`;
    static uploadSpreadsheet: any = () => `${root}/upload-spreadsheet`;
    static listByManufacturer: any = (id: string, skip: number, take: number) => `${root}/list-by-manufacturer?manufacturerid=${id}&skip=${skip}&take=${take}`;
    static listByWholesaler: any = (id: string, skip: number, take: number) => `${root}/list-by-wholesaler?wholesalerid=${id}&skip=${skip}&take=${take}`;
    static listByPractice: any = (id: string, skip: number, take: number) => `${root}/list-by-practice?practiceid=${id}&skip=${skip}&take=${take}`;
    static getAdminReportBreakdown: any = (reportId: string) => `${root}/report/${reportId}`;
    static getPracticeReportBreakdown: any = (reportId: string, practiceId: string) => `${root}/report/${reportId}/${practiceId}`;
    static reconcile: any = () => `${root}/reconcile`;
    static issueRebate: any = () => `${root}/issue-rebate`;
};
