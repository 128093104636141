import { AxiosResponse } from "axios";
import { httpGet, httpPost } from "../modules/backend";

const root = 'xero/tokens'

export interface IHasToken {
    exists: boolean
};

export interface ICreate {
    created: boolean
};

export interface IXeroTokenCreate {
    code: string
};

export class XeroTokensApi {
    setLoading: any;
    setToastMessage: any;

    constructor(setloading: any, setToastMessage: any){
        this.setLoading = setloading;
        this.setToastMessage = setToastMessage;
    }

    async has(): Promise<AxiosResponse<IHasToken>> {
        this.setLoading(true);
        const result = await httpGet(XeroTokensApiEndpoints.has());
        this.setLoading(false);
        return result;
    };

    async create(xeroTokenCreate: IXeroTokenCreate) : Promise<AxiosResponse<ICreate>> {
        this.setToastMessage('Processing');
        this.setLoading(true);
        const result = await httpPost(XeroTokensApiEndpoints.create(), xeroTokenCreate);
        this.setLoading(false);
        return result;
    };
};

export class XeroTokensApiEndpoints {
    static has: any = () => root;
    static create: any = () => root;
};