import { navigate } from '@reach/router';
import Roles from '../domain/auth/Roles';
import { Claims } from './auth/Claims';

export function navigateToLandingPage(user) {
    const role = user[Claims.Role][0];
    if (role) {
        if (role === Roles.AdminUser) {
            practices.navigate();
        } else if (role === Roles.PracticeUser) {
            const practiceId = user[Claims.AppMetaData]?.practice?.id;
            practiceDetail.navigate(practiceId);
        }
        else {
            notFound.navigate();
        }
    }
    // Because this component is now the fallback component for unmatched routing, we'll need to handle not found cases in here see
    // https://reach.tech/router/tutorial/08-default-routes
}

export function navigateHome() {
    root.navigate();
}

export const root = {
    path: '/',
    to: '/',
    navigate: () => { navigate('/') }
};

export const ensureCreated = {
    path: 'ensure-created',
    to: '/ensure-created',
    navigate: () => { navigate('/ensure-created') }
};

export const back = {
    path: '../',
    to: '../',
    navigate: () => { navigate('../') }
};

export const practices = {
    path: 'practices',
    to: '/practices',
    navigate: () => { navigate('/practices') }
};

export const practiceDetail = {
    path: 'practiceDetail/:id',
    to: '/practiceDetail/',
    navigate: (id) => { navigate(`/practiceDetail/${id}`) }
};

export const wholesalers = {
    path: 'wholesalers',
    to: '/wholesalers',
    navigate: () => { navigate('/wholesalers') }
};

export const wholesalerDetail = {
    path: 'wholesalerDetail/:id',
    to: '/wholesalerDetail/',
    navigate: (id) => { navigate(`/wholesalerDetail/${id}`) }
};

export const manufacturers = {
    path: 'manufacturers',
    to: '/manufacturers',
    navigate: () => { navigate('/manufacturers') }
};

export const manufacturerDetail = {
    path: 'manufacturerDetail/:id',
    to: '/manufacturerDetail/',
    navigate: (id) => { navigate(`/manufacturerDetail/${id}`) }
};

export const reports = {
    path: 'reports',
    to: '/reports',
    navigate: () => { navigate('/reports') }
};

export const reportDetail = {
    path: 'reportDetail/:id',
    to: '/reportDetail/',
    navigate: (id) => { navigate(`/reportDetail/${id}`) }
};

export const wholesalerReportWizardPage = {
    path: `reportWizard/wholesaler/:reportId/:reportUploadId`,
    to: '/reportWizard/wholesaler/',
    navigate: (reportId, reportUploadId) => { navigate(`/reportWizard/wholesaler/${reportId}/${reportUploadId}`) }
}

export const manufacturerReportWizardPage = {
    path: `reportWizard/manufacturer/:reportId/:reportUploadId`,
    to: '/reportWizard/manufacturer/',
    navigate: (reportId, reportUploadId) => { navigate(`/reportWizard/manufacturer/${reportId}/${reportUploadId}`) }
}

export const adminReport = {
    path: `report/:reportId`,
    to: '/report/',
    navigate: (reportId) => {navigate(`/report/${reportId}`)}
}

export const practiceReport = {
    path: `report/:reportId/:practiceId`,
    to: '/report/',
    navigate: (reportId, practiceId) => {navigate(`/report/${reportId}/${practiceId}`)}
}

export const reportProducts = {
    path: `report/:reportId/products/:manufacturerId/:practiceId`,
    to: '/report/',
    navigate: (reportId, manufacturerId, practiceId) => {navigate(`/report/${reportId}/products/${manufacturerId}/${practiceId}`)}
}

export const report = {
    to: '/report/'
}

export const notFound = {
    path: 'notfound',
    to: '/notfound',
    navigate: () => {navigate('/notfound')}
};

export const internalServer = {
    path: 'internalserver',
    to: '/internalserver',
    navigate: () => {navigate('/internalserver')}
};

export const forbidden = {
    path: 'forbidden',
    to: '/forbidden',
    navigate: () => {navigate('/forbidden')}
};