import NavLink from '../button/NavLink';

export default function SideMenuItem({name, icon, navigateTo, closeMobileMenu}) {
    return <div className={`margin__top--xs`} >
        <>
        <NavLink to={navigateTo} onClick={closeMobileMenu}>
            <img className="side-menu__item--icon" src={icon} />
            <span className="side-menu__item--name margin__left--xxs">{name}</span>
        </NavLink>
        </>
    </div>
}