import ValueMappingListItem from "./ValueMappingListItem";

export default function ValueMappingList({ header, mappings, options, updateMapping, className, validationLookup }) {
    return <div className={className}>
        <div className="mapping__list__header grid-x">
            <div className="cell small-12 medium-6">
                <p className="mapping__list__header__item">{header}</p>
            </div>
            <div className="cell medium-6 show-for-medium">
                <p className="mapping__list__header__item">Reference in file</p>
            </div>
        </div>
        <div className="mapping__list__items grid-y">
            {mappings && mappings.map((m, i) =>
                <ValueMappingListItem key={i} mapping={m} options={options} updateMapping={updateMapping} validationLookup={validationLookup} />
            )}
        </div>
    </div>
}