import React, { useState, useEffect } from "react";
import { navigate } from '@reach/router';
import * as Navigation from '../../../modules/navigation';
import IconStatusDone from '../../../assets/images/icon-status-done.svg';
import IconStatusNotDone from '../../../assets/images/icon-status-not-done.svg';
import ButtonSecondary from '../../app/button/ButtonSecondary';

export default function ReportsListItem({ id, name, reconciled, rebated, practiceId, navigationOveride }) {
    const [reconciledIcon, setReconciledIcon] = useState(IconStatusNotDone);
    const [rebatedIcon, setRebatedIcon] = useState(IconStatusNotDone);

    useEffect(() => {
        if (reconciled) {
            setReconciledIcon(IconStatusDone);
        }
    }, [reconciled]);

    useEffect(() => {
        
        if (rebated) {
            setRebatedIcon(IconStatusDone);
        }
    }, [rebated]);

    function handelOnClick(){
        if(navigationOveride !== undefined ){
            navigate(`${navigationOveride}${id}`);
        }else{
            navigate(`${Navigation.report.to}${id}${practiceId !== undefined ? `/${practiceId}` : ''}`);
        }
        
    }

    return <div className="cell small-12 medium-6 large-12">
        <div className="grid-x grid-padding-x reports-list__head hide-for-large">
            <div className="cell small-6 display--flex justifyContent--flex-end">
                <p className="reports-list__field">Reconciled</p>
            </div>
            <div className="cell small-6 display--flex">
                <p className="reports-list__field">Rebated</p>
            </div>
        </div>
    
        <div className="grid-x reports-list__item alignItems--center">
            <div className="cell small-12 large-auto xlarge-5 small-order-3 large-order-1 display--flex reports-list__name">
                <p className="reports-list__field">{name}</p>
            </div>
            <div className="cell small-6 large-auto xlarge-2 small-order-1 large-order-2 display--flex reports-list__reconciled">
                <img className="reports-list__field reports-list__icon" src={reconciledIcon} />
            </div>
            <div className="cell small-6 large-auto xlarge-2 small-order-2 large-order-3 display--flex reports-list__rebated">
                <img className="reports-list__field reports-list__icon" src={rebatedIcon} />
            </div>
            <div className="cell small-12 large-auto xlarge-3 small-order-4 display--flex reports-list__view">
                <ButtonSecondary text="View report" className="button--regular button--regular__view-report display--flex reports-list__btn" onClick={handelOnClick} />
            </div>
        </div>
    </div>
};