import React, { useState, useEffect } from "react";
import Modal from '../app/modal/Modal';
import InputText from '../app/form/FormInputText';
import ButtonPrimary from '../app/button/ButtonPrimary';

export default function ModalEditPracticeUser({ isActive, onClose, onEditPracticeUser, existingFirstName, existingLastName, existingEmail, error, ...others }) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');

    function onFirstNameChanged(e) {
        setFirstName(e.target.value);
    };

    function onLastNameChanged(e) {
        setLastName(e.target.value);
    };

    function onEmailAddressChanged(e) {
        setEmailAddress(e.target.value);
    }

    function editPracticeUser(event) {
        if (event !== undefined && event !== null) {
            event.preventDefault();
        }
        
        var payload = {
            firstName : firstName,
            lastName: lastName,
            emailAddress: emailAddress
        };
        onEditPracticeUser(payload);
    };

    useEffect(() => {
        setFirstName(existingFirstName);
        setLastName(existingLastName);
        setEmailAddress(existingEmail);
    }, [isActive]);

    return isActive && <Modal onClose={() => onClose(false)}>
    <h3 className="modal__content-title textAlign--center margin__bottom--m">Edit Practice User</h3>
    <form onSubmit={editPracticeUser} id="edit-practice-user-form" >
        <InputText className="margin__bottom--m" id="edit-practice-user-first-name" label="First name" name="firstName" value={firstName} required={true} onChange={onFirstNameChanged} />
        <InputText className="margin__bottom--m" id="edit-practice-user-last-name" label="Last name" name="lastName" required={true} value={lastName} onChange={onLastNameChanged} />
        <InputText className="margin__bottom--m" id="edit-practice-user-email-address" label="Email address" name="emailAddress" required={true} value={emailAddress} onChange={onEmailAddressChanged} />

        { error !== "" &&
            <p className="small input__error">{error}</p>
        }
        <div className="textAlign--right">
            <ButtonPrimary id="edit-practice-user-button" type="submit" text="Save" className="button--regular"/>
        </div>
    </form>
</Modal>
}