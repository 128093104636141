import React, { useEffect, useState } from "react";
import SideMenuItem from './side-menu-item';
import * as Navigation from '../../../modules/navigation';
import Logo from '../../../assets/images/logo-veep.svg';
import IconCross from '../../../assets/images/icon-cross.svg';
import IconDroplet from '../../../assets/images/icon-droplet.svg';
import IconWrench from '../../../assets/images/icon-wrench.svg';
import IconBox from '../../../assets/images/icon-box.svg';
import IconChart from '../../../assets/images/icon-chart.svg';
import { RenderByRole } from "../auth/RenderByRole";
import Roles from "../../../domain/auth/Roles";
import { useAppMetaData } from "../../../modules/auth/useAppMetaData";

export default function SideMenu({ showMobileMenu, setShowMobileMenu }) {

    const [appMetaData] = useAppMetaData();
    const [menuComponents, setMenuComponents] = useState(undefined);

    useEffect(() => {
        if (appMetaData) {
            setMenuComponents({
                [Roles.AdminUser]: adminUserMenuItems(),
                [Roles.PracticeUser]: practiceUserMenuItems()
            })
        }
    }, [appMetaData])

    useEffect(() => {
    }, [showMobileMenu])

    function closeMobileMenu() {
        setShowMobileMenu(false)
    }

    function adminUserMenuItems() {
        return <>
            <SideMenuItem name={'Practices'} icon={IconDroplet} navigateTo={Navigation.practices.to} closeMobileMenu={closeMobileMenu} />
            <SideMenuItem name={'Wholesalers'} icon={IconWrench} navigateTo={Navigation.wholesalers.to} closeMobileMenu={closeMobileMenu} />
            <SideMenuItem name={'Manufacturers'} icon={IconBox} navigateTo={Navigation.manufacturers.to} closeMobileMenu={closeMobileMenu} />
            <SideMenuItem name={'Reports'} icon={IconChart} navigateTo={Navigation.reports.to} closeMobileMenu={closeMobileMenu} />
        </>
    }

    function practiceUserMenuItems() {
        const practiceId = appMetaData.practice?.id
        if (practiceId) {
            const myPracticeUrl = `${Navigation.practiceDetail.to}${practiceId}`;
            return <>
                <SideMenuItem name={'My Practice'} icon={IconDroplet} navigateTo={myPracticeUrl} closeMobileMenu={closeMobileMenu} />
            </>
        }
    }

    return <>
        {showMobileMenu &&
            <div className="side-menu__mobile-menu-backing" onClick={closeMobileMenu}></div>
        }

        <div className={`side-menu ${showMobileMenu === true ? "side-menu__mobile-menu-open" : "side-menu__mobile-menu-closed"}`}>
            <div className="side-menu__mobile-header">
                <img className="side-menu__mobile-header__logo" src={Logo} alt="Logo" onClick={Navigation.root.navigate}/>
                <button className="side-menu__mobile-close-btn" onClick={closeMobileMenu}>
                    <img src={IconCross} alt="Close menu button" />
                </button>
            </div>
            {menuComponents && <RenderByRole components={menuComponents} />}
        </div>
    </>
}