import ContentArea from "../../symbols/app/content/content-area";
import {RenderByRole} from "../../symbols/app/auth/RenderByRole";
import Roles from "../../domain/auth/Roles";
import InlineInputText from "../../symbols/app/form/InlineInputText";
import React, {useEffect, useState} from "react";
import {useHttp} from "../../context/HttpProvider";
import Tabs from "../../symbols/app/tabs/Tabs";
import IconView from "../../assets/images/icon-view.png";
import {navigate} from "@reach/router";
import * as Navigation from "../../modules/navigation";
import HttpStatusCodes from "../../modules/httpStatusCodes";
import Archive from "../../symbols/archiving/Archive";
import ButtonSecondary from "../../symbols/app/button/ButtonSecondary";
import ModalAddProduct from "../../symbols/products/ModalAddProduct";
import ProductList from "../../symbols/products/product-list";
import ReportsList from "../../symbols/app/list/reports-list";

export default function ManufacturerDetailPage({id}) {
    const [manufacturerLoaded, setManufacturerLoaded] = useState(false);
    const {manufacturersApi, productsApi, reportsApi } = useHttp();
    const [manufacturer, setManufacturer] = useState({});
    const [error, setError] = useState(false);
    const [showConfirmArchiveManufacturer, setShowConfirmArchiveManufacturer] = useState(false);
    const [archiveManufacturerError, setArchiveManufacturerError] = useState()
    const [showProductModal, setShowProductModal] = useState(false);
    const [createProductError, setCreateProductError] = useState();
    const [productTotalCount, setProductTotalCount] = useState(0);
    const [productCurrentPage, setProductCurrentPage] = useState(0);
    const [reportsTotalCount, setReportsTotalCount] = useState(0);
    const [reports, setReports] = useState();
    const [products, setProducts] = useState();
    const [includeArchived, setIncludeArchived] = useState(false);
    const [productsLoaded, setProductsLoaded] = useState(false);
    const [openTabsAtIndex, setOpenTabsAtIndex] = useState(null);
    const perPage = process.env.REACT_APP_PAGE_SIZE;

    async function getManufacturer() {
        var response = await manufacturersApi.get(id);
        if(response) {
            setManufacturer(response);
            setManufacturerLoaded(true);
        }
    }

    async function getReports(skip){
        var response = await reportsApi.listByManufacturer(id, skip, perPage);
        if (response) {
            setReports(response.reports);
            setReportsTotalCount(response.totalCount);
        }
    };

    async function getProducts(skip, showArchived) {
        var response = await productsApi.listByManufacturer(id, showArchived != undefined ? showArchived : includeArchived, skip, perPage);
        if(response) {
            if(response.status !== undefined && response.status !== HttpStatusCodes.Ok) {
                setCreateProductError(response.data[0]);
            } else {
                setCreateProductError(null);
                setProducts(response.products);
                setProductTotalCount(response.totalCount);
                setProductsLoaded(true);
            }
        }
    }

    async function updateManufacturerName(value) {
        var payload = {
            id: id,
            name: value
        };
        var response = await manufacturersApi.edit(payload);
        if (response.status === HttpStatusCodes.Ok) {
            getManufacturer();
        } else {
            setError(response.data[0]);
        }
    }
    
    async function archiveManufacturer() {
        const response = await manufacturersApi.archive(id);
        if (response.status === HttpStatusCodes.Ok) {
            getManufacturer();
            setShowConfirmArchiveManufacturer(false);
        } else {
            setArchiveManufacturerError(response.data[0]);
        }
    }

    async function createProduct(payload) {
        const response = await productsApi.create(payload);
        if(response.status === HttpStatusCodes.Created) {
            setCreateProductError('');
            setShowProductModal(false);
            getProducts(0);
            setOpenTabsAtIndex(1);
        } else {
            setCreateProductError(response.data[0]);
        }
    }

    function closeConfirmArchivePracticeModal() {
        setShowConfirmArchiveManufacturer(false);
        setArchiveManufacturerError('');
    }

    function onArchiveManufacturerConfirmed() {
        archiveManufacturer();
    }

    async function handlePageClick(data) {
        let selected = data.selected;
        setProductCurrentPage(selected)
        await getProducts(selected * perPage)
    }

    async function changeIncludeArchived() {
        var include = !includeArchived;
        setIncludeArchived(include);
        await getProducts(0, include)
    }

    function navigateToList() {
        navigate(Navigation.manufacturers.to);
    }

    useEffect(() => {
        if(manufacturersApi != null && productsApi !== null && reportsApi !== null) {
            getManufacturer();
            getProducts(0);
            getReports(0);
        }
    }, [manufacturersApi])

    return <ContentArea>
        <div className="detail-page__back-wrapper" onClick={navigateToList}>
            <img src={IconView} className="detail-page__back" />
            <p>Back to Manufacturers</p>
        </div>
        { manufacturerLoaded &&
            <>
                <div className="content-area__header">
                    <div className="content-area__header__left-container">
                        <div className="show-for-medium">
                            <RenderByRole key={manufacturer.name}>
                                    <h1 id="manufacturer-name" supportedrole={Roles.PracticeUser}>{manufacturer.name}</h1>
                                    <InlineInputText supportedrole={Roles.AdminUser} value={manufacturer.name} saveFunction={updateManufacturerName} error={error}>
                                        <h1 id="manufacturer-name-admin">{manufacturer.name}</h1>
                                    </InlineInputText>
                            </RenderByRole>
                        </div>
                        <div className="show-for-small-only">
                            <RenderByRole key={manufacturer.name}>
                                <h3 id="manufacturer-name" supportedrole={Roles.PracticeUser}>{manufacturer.name}</h3>
                                <InlineInputText supportedrole={Roles.AdminUser} value={manufacturer.name} saveFunction={updateManufacturerName} error={error}>
                                    <h3 id="manufacturer-name-admin">{manufacturer.name}</h3>
                                </InlineInputText>
                            </RenderByRole>
                        </div>
                    </div>
                    <div className="content-area__header__right-container">
                        <ButtonSecondary id="product-add" text="New product" className="button--regular button--regular__add display--flex" onClick={() => setShowProductModal(!showProductModal)} />
                    </div>
                </div>

                <Tabs labels={['Reports', 'Products']} openTabsAtIndex={openTabsAtIndex} setOpenTabsAtIndex={setOpenTabsAtIndex}>
                    <ReportsList reports={reports} getReports={getReports} totalCount={reportsTotalCount} />

                    <ProductList items={products}
                                 totalCount={productTotalCount}
                                 currentPage={productCurrentPage}
                                 handlePageClick={handlePageClick}
                                 includeArchived={includeArchived}
                                 changeIncludeArchived={changeIncludeArchived}
                                 productsLoaded={productsLoaded}
                                 getProducts={getProducts}/>
                </Tabs>

                {manufacturer && <Archive target={manufacturer} type={'Manufacturer'} 
                    onClick={() => setShowConfirmArchiveManufacturer(true)}  
                    isModalActive={showConfirmArchiveManufacturer}
                    onArchiveConfirmed={onArchiveManufacturerConfirmed}
                    onModalClose={closeConfirmArchivePracticeModal}
                    archiveError={archiveManufacturerError}
                />}
            </>
        }
        <ModalAddProduct manufacturerId={id}
                         isActive={showProductModal}
                         onSubmit={createProduct}
                         onClose={() => setShowProductModal(false)}
                         error={createProductError}/>
    </ContentArea>
}