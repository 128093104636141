import {AxiosResponse} from "axios";
import {httpGet, httpPost} from "../modules/backend";

const root = 'products'

export interface IProduct {
    id: string,
    name: string,
    discount: number,
    isArchived: boolean
}

export class ProductsApi {
    setLoading : any;
    setToastMessage: any;

    constructor(setloading: any, setToastMessage: any){
        this.setLoading = setloading;
        this.setToastMessage = setToastMessage;
    }

    async listByManufacturer(manufacturerId: string, includeArchived: boolean, skip: number, take: number): Promise<AxiosResponse<IProduct[]>> {
        this.setLoading(true);
        const result = await httpGet(ProductApiEndpoints.listByManufacturer(manufacturerId, includeArchived, skip, take));
        this.setLoading(false);
        return result;
    }

    async create(product: IProduct) : Promise<AxiosResponse<any>> {
        this.setToastMessage('Processing')
        this.setLoading(true);
        const result = httpPost(ProductApiEndpoints.create(), product);
        this.setLoading(false);
        return result;
    }

    async archive(id: string) : Promise<AxiosResponse<any>> {
        this.setToastMessage('Processing')
        var payload = {
            id: id
        }
        this.setLoading(true);
        const result = httpPost(ProductApiEndpoints.archive(), payload);
        this.setLoading(false);
        return result;
    }

    async getProductsBreakdownByReportAndManufacturer(reportId: string, manufacturerId: string, practiceId: string) {
        this.setLoading(true);
        const result = await httpGet(ProductApiEndpoints.getProductsBreakdownByReportAndManufacturer(reportId, manufacturerId, practiceId));
        this.setLoading(false);
        return result;
    }
}

export class ProductApiEndpoints {
    static listByManufacturer: any = (manufacturerId: string, includeArchived: boolean, skip: number, take: number) => `${root}/list-by-manufacturer?manufacturerId=${manufacturerId}&includeArchived=${includeArchived}&skip=${skip}&take=${take}`;
    static create: any = () => root;
    static archive: any = () => `${root}/archive`;
    static getProductsBreakdownByReportAndManufacturer: any = (reportId: string, manufacturerId: string, practiceId:string) => `${root}/${reportId}/${manufacturerId}/${practiceId}`;
}