import { AxiosResponse } from "axios";
import { ReportType } from "../domain/reports/ReportType";
import { httpPost, httpGet } from "../modules/backend";

const root = 'mapping';

interface IColumnMappings {
    requiredFields: IRequiredField[];
    columns: IColumn[];
    mappings: any, // This is a plain JS object (key / value pairs) -> Maps to Dictionary<string, int>
    fileContainsHeader: boolean,
    skipRows: number
}

interface IRequiredField {
    value: string,
    displayValue: string
}

interface IColumn {
    number: number,
    name: string,
    sampleValue: string
}

interface IMappingSettings {
    fileContainsHeader: boolean,
    skipRows: number
}

interface IValueMapping {
    mappings: IMapping[],
    options: string[]
}

interface IMapping {
    id: string,
    name: string,
    mappingValue: string
}

interface IValueMappingPayload {
    reportUploadId: string,
    mappings: IMapping[],
    options: string[]
}

export default class MappingApi {
    setLoading : any;
    setToastMessage: any;

    constructor(setloading: any, setToastMessage: any){
        this.setLoading = setloading;
        this.setToastMessage = setToastMessage;
    }
    
    async getColumnMappings(reportUploadId: string, reportType: typeof ReportType) : Promise<AxiosResponse<IColumnMappings>> {
        this.setLoading(true);
        const result = await httpGet(MappingApiEndpoints.getColumnMappings(reportUploadId, reportType));
        this.setLoading(false);
        return result;
    }

    async saveColumnMappings(reportUploadId: string, reportType: typeof ReportType, mappings: any) : Promise<AxiosResponse<any>> {
        this.setToastMessage('Processing')
        this.setLoading(true);
        const payload = {
            reportUploadId,
            reportType,
            mappings
        }
        const result = await httpPost(MappingApiEndpoints.saveColumnMappings(), payload);
        this.setLoading(false);
        return result;
    }

    async saveSettings(reportUploadId: string, reportType: typeof ReportType, fileContainsHeader: boolean, skipRows: number) : Promise<AxiosResponse<any>> {
        this.setToastMessage('Processing')
        this.setLoading(true);
        const payload = {
            reportUploadId,
            reportType,
            fileContainsHeader,
            skipRows: typeof skipRows === 'string' ? Number(skipRows) : skipRows
        }
        const result = await httpPost(MappingApiEndpoints.saveSettings(), payload);
        this.setLoading(false);
        return result;
    }

    async getSettings(reportUploadId: string, reportType: typeof ReportType) : Promise<AxiosResponse<IMappingSettings>> {
        this.setLoading(true);
        const result = await httpGet(MappingApiEndpoints.getSettings(reportUploadId, reportType));
        this.setLoading(false);
        return result;
    }

    async getManufacturerMappings(id: string) : Promise<AxiosResponse<IValueMapping[]>> {
        this.setLoading(true);
        const result = await httpGet(MappingApiEndpoints.getManufacturerMappings(id));
        this.setLoading(false);
        return result;
    }

    async getWholesalerMappings(id: string) : Promise<AxiosResponse<IValueMapping[]>> {
        this.setLoading(true);
        const result = await httpGet(MappingApiEndpoints.getWholesalerMappings(id));
        this.setLoading(false);
        return result;
    }

    async getWholesalerPracticeMappings(id: string) : Promise<AxiosResponse<IValueMapping[]>> {
        this.setLoading(true);
        const result = await httpGet(MappingApiEndpoints.getWholesalerPracticeMappings(id));
        this.setLoading(false);
        return result;
    }

    async getManufacturerPracticeMappings(id: string) : Promise<AxiosResponse<IValueMapping[]>> {
        this.setLoading(true);
        const result = await httpGet(MappingApiEndpoints.getManufacturerPracticeMappings(id));
        this.setLoading(false);
        return result;
    }

    async getManufacturerProductMappings(id: string) : Promise<AxiosResponse<IValueMapping[]>> {
        this.setLoading(true);
        const result = await httpGet(MappingApiEndpoints.getManufacturerProductMappings(id));
        this.setLoading(false);
        return result;
    }

    async getWholesalerProductMappings(id: string) : Promise<AxiosResponse<IValueMapping[]>> {
        this.setLoading(true);
        const result = await httpGet(MappingApiEndpoints.getWholesalerProductMappings(id));
        this.setLoading(false);
        return result;
    }

    async saveManufacturerMappings(payload: IValueMappingPayload) : Promise<AxiosResponse> {
        this.setToastMessage('Processing')
        this.setLoading(true);
        const result = await httpPost(MappingApiEndpoints.saveManufacturerMappings(), payload);
        this.setLoading(false);
        return result;
    }

    async saveManufacturerProductMappings(payload: IValueMappingPayload) : Promise<AxiosResponse> {
        this.setToastMessage('Processing')
        this.setLoading(true);
        const result = await httpPost(MappingApiEndpoints.saveManufacturerProductMappings(), payload);
        this.setLoading(false);
        return result;
    }

    async saveWholesalerProductMappings(payload: IValueMappingPayload) : Promise<AxiosResponse> {
        this.setToastMessage('Processing')
        this.setLoading(true);
        const result = await httpPost(MappingApiEndpoints.saveWholesalerProductMappings(), payload);
        this.setLoading(false);
        return result;
    }

    async saveManufacturerPracticeMappings(payload: IValueMappingPayload) : Promise<AxiosResponse> {
        this.setToastMessage('Processing')
        this.setLoading(true);
        const result = await httpPost(MappingApiEndpoints.saveManufacturerPracticeMappings(), payload);
        this.setLoading(false);
        return result;
    }

    async saveWholesalerMappings(payload: IValueMappingPayload) : Promise<AxiosResponse> {
        this.setToastMessage('Processing')
        this.setLoading(true);
        const result = await httpPost(MappingApiEndpoints.saveWholesalerMappings(), payload);
        this.setLoading(false);
        return result;
    }
}

export class MappingApiEndpoints {
    static getColumnMappings: any = (reportUploadId: string, reportType: typeof ReportType) => `${root}/get-column-mappings?ReportUploadId=${reportUploadId}&ReportType=${reportType}`;
    static saveColumnMappings: any = () => `${root}/save-column-mappings`;

    static saveSettings: any = () => `${root}/save-settings`;
    static getSettings: any = (reportUploadId: string, reportType: typeof ReportType) => `${root}/get-settings?ReportUploadId=${reportUploadId}&ReportType=${reportType}`;

    static getManufacturerMappings: any = (id: string) => `${root}/manufacturer-mappings?reportUploadId=${id}`;
    static getWholesalerMappings: any = (id: string) => `${root}/wholesaler-mappings?reportUploadId=${id}`;
    static getWholesalerPracticeMappings: any = (id: string) => `${root}/wholesaler-practice-mappings?reportUploadId=${id}`;
    static getManufacturerPracticeMappings: any = (id: string) => `${root}/manufacturer-practice-mappings?reportUploadId=${id}`;
    static getManufacturerProductMappings: any = (id: string) => `${root}/manufacturer-product-mappings?reportUploadId=${id}`;
    static getWholesalerProductMappings: any = (id: string) => `${root}/wholesaler-product-mappings?reportUploadId=${id}`;

    static saveManufacturerMappings: any = () => `${root}/manufacturer-mappings`;
    static saveManufacturerProductMappings: any = () => `${root}/manufacturer-product-mappings`;
    static saveWholesalerProductMappings: any = () => `${root}/wholesaler-product-mappings`;
    static saveManufacturerPracticeMappings: any = () => `${root}/manufacturer-practice-mappings`;
    static saveWholesalerMappings: any = () => `${root}/wholesaler-mappings`;
}
