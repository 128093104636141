import React, { useState } from "react";
import ReportsListItem from "./reports-list-item";
import Pagination from "../../../symbols/app/list/pagination";

export default function ReportsList({ reports, getReports, totalCount, practiceId, navigationOveride }) {
    const perPage = process.env.REACT_APP_PAGE_SIZE;
    const [currentPage, setCurrentPage] = useState(0);

    function handlePageClick(data) {
        let selected = data.selected;
        setCurrentPage(selected)
        getReports(selected * perPage);
    };

    return <div className="cell small-12 reports-list">
        <div className="grid-x grid-margin-x reports-list__head show-for-large">
            <div className="cell small-12 large-auto xlarge-5">
                <p className="reports-list__title">Name</p>
            </div>
            <div className="cell small-12 large-auto xlarge-2">
                <p className="reports-list__title">Reconciled</p>
            </div>
            <div className="cell small-12 large-auto xlarge-2">
                <p className="reports-list__title">Rebated</p>
            </div>
            <div className="cell small-12 large-auto xlarge-3">
            </div>
        </div>

        <div className="grid-x grid-margin-x">
            { reports && reports.map((item, i) => <ReportsListItem id={item.id} name={item.name} reconciled={item.reconciled} rebated={item.rebated} key={`reports-list-${i}`} practiceId={practiceId} navigationOveride={navigationOveride}/>) }
        </div>

        { reports && totalCount > perPage &&
            <div className="grid-x">
                <div className="cell small-12 display--flex justifyContent--center margin__top--xl">
                    <Pagination currentPage={currentPage} pageSize={perPage} totalResults={totalCount} description={'monthly reports'} onPageChanged={handlePageClick}/>
                </div>
            </div>
        }
    </div>
}