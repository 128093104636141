import React, { useState, useEffect } from "react";
import { Router } from '@reach/router';
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from './features/AuthApi';
import { HttpProvider } from './context/HttpProvider';
import * as Utils from './modules/utils';
import * as Navigation from "./modules/navigation";
import Secure from "./symbols/app/auth/Secure";
import Toast from "./symbols/app/toast/Toast";
import HomePage from './pages/home/HomePage';
import PracticesPage from './pages/practices/PracticesPage';
import PracticeDetailPage from './pages/practices/PracticeDetailPage';
import ReportsPage from './pages/reports/ReportsPage';
import EnsureCreated from './symbols/app/auth/EnsureCreated';
import Header from './symbols/app/header/header';
import SideMenu from './symbols/app/menu/side-menu';
import WholesalersPage from "./pages/wholesalers/WholesalersPage";
import ManufacturersPage from './pages/manufacturers/ManufacturersPage';
import WholesalerDetailPage from "./pages/wholesalers/WholesalerDetailPage";
import ManufacturerDetailPage from "./pages/manufacturers/ManufacturerDetailPage";
import WholesalerReportWizardPage from "./pages/report-wizard/WholesalerReportWizardPage";
import ManufacturerReportWizardPage from "./pages/report-wizard/ManufacturerReportWizardPage";
import ReportDetailPage from "./pages/reports/ReportDetailPage";
import UserDeleted from "./symbols/app/signalR/UserDeleted/UserDeleted";
import ForbiddenError from "./pages/error/ForbiddenError";
import InternalServerError from "./pages/error/InternalServerError";
import NotFoundError from "./pages/error/NotFoundError";
import AdminReportOverview from "./pages/reports/AdminReportOverview";
import PracticeReportOverview from "./pages/reports/PracticeReportOverview";
import ReportProductsPage from "./pages/products/ReportProducts";

function App() {
  const [config, setConfig] = useState(null);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    async function getAuthConfig() {
      const result = await getConfig();
      setConfig(result);
    }
    getAuthConfig();
  }, [])

  function getRedirectUri() {
    return `${window.location.origin}/${Utils.stripLeadingSlash(Navigation.ensureCreated.path)}`;
  }

  return config ? <Auth0Provider 
                      domain={config.domain} 
                      clientId={config.clientId} 
                      redirectUri={getRedirectUri()} 
                      audience={config.audience} 
                      scope={config.scope}>
        <HttpProvider>
              
              <Toast />
              <Secure path={Navigation.root.path}>
                <div className="grid-container grid-container--no-padding">
                    <div className="grid-x">
                        <div className="cell shrink">
                            <SideMenu showMobileMenu={showMobileMenu} setShowMobileMenu={setShowMobileMenu}/>
                        </div>
                      <UserDeleted />
                      <div className="cell auto">
                      <Header setShowMobileMenu={setShowMobileMenu}/>
                        <Router>
                            <HomePage path={Navigation.root.path} />
                            <EnsureCreated  path={Navigation.ensureCreated.path} />
                            <PracticesPage path={Navigation.practices.path} />
                            <PracticeDetailPage path={Navigation.practiceDetail.path} />
                            <WholesalersPage path={Navigation.wholesalers.path} />
                            <ManufacturersPage path={Navigation.manufacturers.path} />
                            <WholesalerDetailPage path={Navigation.wholesalerDetail.path}/>
                            <ManufacturerDetailPage path={Navigation.manufacturerDetail.path} />
                            <WholesalerReportWizardPage path={Navigation.wholesalerReportWizardPage.path} />
                            <ManufacturerReportWizardPage path={Navigation.manufacturerReportWizardPage.path} />
                            <ReportsPage path={Navigation.reports.path}/>
                            <ReportDetailPage path={Navigation.reportDetail.path} />
                            <NotFoundError default={true} path={Navigation.notFound.path}/>
                            <AdminReportOverview path={Navigation.adminReport.path}/>
                            <PracticeReportOverview path={Navigation.practiceReport.path}/>
                            <ReportProductsPage path={Navigation.reportProducts.path} />
                            <InternalServerError path={Navigation.internalServer.path}/>
                            <ForbiddenError path={Navigation.forbidden.path}/>
                        </Router>
                      </div>
                    </div>
                </div>
            </Secure>
        </HttpProvider>
    </Auth0Provider> : <></>
}

export default App;
