import MappingListItem from "./ColumnMappingListItem";

export default function MappingList({ header, mappingData, specifiedMappings, updateMapping, className, validationLookup }) {
    return <div className={className}>
        <div className="mapping__list__header grid-x">
            <div className="cell small-12 medium-6">
                <p className="mapping__list__header__item">{header}</p>
            </div>
            <div className="cell medium-6 show-for-medium">
                <p className="mapping__list__header__item">Reference in file</p>
            </div>
        </div>
        <div className="mapping__list__items grid-y">
            {mappingData && mappingData.requiredFields.map((m, i) =>
                <MappingListItem key={i} mapping={m} options={mappingData.columns} updateMapping={updateMapping} specifiedMappings={specifiedMappings} validationLookup={validationLookup} />
            )}
        </div>
    </div>
}