import React, { useState, useEffect } from "react";
import ButtonBack from "../../symbols/app/button/ButtonBack";
import {navigate} from "@reach/router";
import * as Navigation from "../../modules/navigation";
import ContentArea from "../../symbols/app/content/content-area";
import ReportSection from "../../symbols/report/report-section";
import ReportTotal from "../../symbols/report/report-totals";
import { useHttp } from '../../context/HttpProvider';
import { RenderByRole } from "../../symbols/app/auth/RenderByRole";
import Roles from "../../domain/auth/Roles";

export default function PracticeReportOverview({reportId, practiceId}) {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [report, setReport] = useState(null);
    const { reportsApi } = useHttp();

    async function getReport() {
        const response = await reportsApi.getPracticeReportBreakdown(reportId, practiceId);
        if(response) {
            setReport(response);
            setHasLoaded(true);
        }
    };

    function navigateToDetailPage() {
        navigate(`${Navigation.practiceDetail.to}${practiceId}`);
    }

    useEffect(() => {
        if (reportsApi != null) {
            getReport();
        }
    }, [reportsApi]);

    return <ContentArea>
        <RenderByRole>
            <ButtonBack id="button-back-to-reports" supportedrole={Roles.AdminUser} onClick={() => navigate(Navigation.reports.to)} text="Back to Reports" className="margin__bottom--xs"/>
            <ButtonBack id="button-back-to-my-practice" supportedrole={Roles.PracticeUser} onClick={() => navigateToDetailPage()} text="Back to My Practice" className="margin__bottom--xs"/>
        </RenderByRole> 

        { hasLoaded &&
            <>
                <div className="grid-x grid-padding-y alignItems--center margin__bottom--s">
                    <div className="cell small-12">
                        <h2 id="report-practice-name" className="margin__bottom--m">{report.practiceName}</h2>
                        <div className="report__sub-title__wrapper">
                            <p id="report-name" className="report__sub-title">{report.name}</p>
                            <p id="report-rebate-total" className="report__sub-title"><b>Rebate</b> {report.totals.rebateTotalFormatted}</p>
                        </div>
                    </div>
                </div>

                <div className="margin__bottom--xl">
                    {report.sections.map((section, i) => <ReportSection reportSection={section} reportId={reportId} key={i} practiceId={practiceId}/>)}
                </div>
                <div>
                    <ReportTotal totals={report.totals}/>
                </div>
            </>
        }
    </ContentArea>
}