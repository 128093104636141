import {useHttp} from "../../../context/HttpProvider";
import {useEffect, useState} from "react";
import { ContinueAction } from "../ReportWizardProvider";
import useValueMapping from "../mapping/hooks/useValueMapping";
import MapEntity from "./MapEntity";

export default function ManufacturerProductMapper() {
    const {mappingApi} = useHttp();
    const [hasLoaded, setHasLoaded] = useState(false);
    const [reportUploadId, mappingData, setMappingData, updateMapping, validate, validationLookup] = useValueMapping(ContinueAction.SaveManufacturerProductMappings);

    async function getProductMappings() {
        var response = await mappingApi.getManufacturerProductMappings(reportUploadId);
        if(response) {
            setMappingData(response);
            setHasLoaded(true);
            validate(response);
        }
    }

    useEffect(() => {
        if(mappingApi != null) {
            getProductMappings();
        }
    }, [mappingApi])

    return <>
        {hasLoaded && <MapEntity data={mappingData} type="Product" updateMapping={updateMapping} validationLookup={validationLookup} />}
    </>
}