import React, { useState, useContext } from 'react';
import { useHttp } from '../../context/HttpProvider';
import httpStatusCodes from '../../modules/httpStatusCodes';
import * as Navigation from "../../modules/navigation";

const ReportWizardContext = React.createContext();

const ContinueAction = {
    None: 0,
    SaveColumnMappings: 1,
    SaveManufacturerMappings: 2,
    SaveManufacturerProductMappings: 3,
    SaveWholesalerProductMappings: 4,
    SaveManufacturerPracticeMappings: 5,
    SaveWholesalerMappings: 6,
}

function ReportWizardProvider(props) {

    const { reportType, reportUploadId, reportId } = props;

    const { mappingApi } = useHttp();
    const [mappingErrors, setMappingErrors] = useState([]);
    const [showMappingErrors, setShowMappingErrors] = useState(false);
    const [smallContent, setSmallContent] = useState(false);
    const [continueAction, setContinueAction] = useState(ContinueAction.None);
    const [mappingData, setMappingData] = useState();
    const [fileContainsHeader, setFileContainsHeader] = useState(false);
    const [skipRows, setSkipRows] = useState(0);
    const [validToMoveForward, setValidToMoveForward] = useState(false);

    const continueActionList = {
        [ContinueAction.None]: noAction,
        [ContinueAction.SaveColumnMappings]: saveColumnMappings,
        [ContinueAction.SaveManufacturerMappings]: saveManufacturerMappings,
        [ContinueAction.SaveManufacturerProductMappings]: saveManufacturerProductMappings,
        [ContinueAction.SaveWholesalerProductMappings]: saveWholesalerProductMappings,
        [ContinueAction.SaveManufacturerPracticeMappings]: saveManufacturerPracticeMappings,
        [ContinueAction.SaveWholesalerMappings]: saveWholesalerMappings
    }

    async function noAction() {
        return true;
    }

    async function saveColumnMappings() {
        const response = await mappingApi.saveColumnMappings(reportUploadId, reportType, mappingData);
        const success = response.status === httpStatusCodes.Ok;
        if(success) {
            setMappingErrors([]);
            setShowMappingErrors(false);
        } else {            
            setMappingErrors(response.data);
            setShowMappingErrors(true);
        }
        return success;
    }

    async function saveManufacturerMappings() {
        const payload = {
            reportUploadId, 
            mappings: mappingData.mappings
        }
        const response = await mappingApi.saveManufacturerMappings(payload);
        const success = response.status === httpStatusCodes.Ok;
        if(success) {
            setMappingErrors([]);
            setShowMappingErrors(false);
        } else {            
            setMappingErrors(response.data);
            setShowMappingErrors(true);
        }
        return success;
    }

    async function saveManufacturerProductMappings() {
        const payload = {
            reportUploadId, 
            mappings: mappingData.mappings
        }
        const response = await mappingApi.saveManufacturerProductMappings(payload);
        const success = response.status === httpStatusCodes.Ok;
        if(success) {
            setMappingErrors([]);
            setShowMappingErrors(false);
        } else {            
            setMappingErrors(response.data);
            setShowMappingErrors(true);
        }
        return success;
    }

    async function saveWholesalerProductMappings() {
        const payload = {
            reportUploadId, 
            mappings: mappingData.mappings
        }
        const response = await mappingApi.saveWholesalerProductMappings(payload);
        const success = response.status === httpStatusCodes.Ok;
        if(success) {
            setMappingErrors([]);
            setShowMappingErrors(false);
        } else {            
            setMappingErrors(response.data);
            setShowMappingErrors(true);
        }
        return success;
    }

    async function saveManufacturerPracticeMappings() {
        const payload = {
            reportUploadId, 
            mappings: mappingData.mappings
        }
        const response = await mappingApi.saveManufacturerPracticeMappings(payload);
        const success = response.status === httpStatusCodes.Ok;
        if(success) {
            setMappingErrors([]);
            setShowMappingErrors(false);
        } else {            
            setMappingErrors(response.data);
            setShowMappingErrors(true);
        }
        return success;
    }

    async function saveWholesalerMappings() {
        const payload = {
            reportUploadId, 
            mappings: mappingData.mappings
        }
        const response = await mappingApi.saveWholesalerMappings(payload);
        const success = response.status === httpStatusCodes.Ok;
        if(success) {
            setMappingErrors([]);
            setShowMappingErrors(false);
        } else {            
            setMappingErrors(response.data);
            setShowMappingErrors(true);
        }
        return success;
    }

    async function processContinue() {
        const continueFunction = continueActionList[continueAction ?? ContinueAction.None];
        return await continueFunction();
    }

    async function onContinue() {
        let processed = false;
        if (processContinue) {
            processed = await processContinue();
            if(isLast()) {
                Navigation.reportDetail.navigate(reportId);
            }
        }
        return processed;
    }

    function isLast() {
        return continueAction === ContinueAction.SaveWholesalerProductMappings || continueAction === ContinueAction.SaveManufacturerProductMappings;
    }

    function onBack() {
        setValidToMoveForward(false);
        setContinueAction(ContinueAction.None);
    }

    return <ReportWizardContext.Provider value={{
        reportType,
        reportUploadId,
        smallContent,
        setSmallContent,
        mappingData,
        setMappingData,
        setContinueAction,
        mappingErrors,
        showMappingErrors,
        setShowMappingErrors,
        fileContainsHeader,
        setFileContainsHeader,
        skipRows,
        setSkipRows,
        validToMoveForward,
        setValidToMoveForward,
        onContinue,
        onBack
    }} {...props} />
}

function useReportWizard() {
    const context = useContext(ReportWizardContext)
    return context;
}

export { ReportWizardProvider, useReportWizard, ContinueAction }