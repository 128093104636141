import React, { useState, useEffect } from "react";
import IconUpload from '../../../assets/images/icon-upload.svg';
import IconStatusDone from '../../../assets/images/icon-status-done.svg';
import IconStatusNotDone from '../../../assets/images/icon-status-not-done.svg';
import { navigate } from '@reach/router';
import * as Navigation from '../../../modules/navigation';

export default function ReportDataListItem({id, type, name, filename, reconciled, totalFormatted, reportId }) {

    const [reconciledIcon, setReconciledIcon] = useState(IconStatusNotDone);
    const [navigateTo, setNavigateTo] = useState('');

    useEffect(() => {
        if (reconciled) {
            setReconciledIcon(IconStatusDone);
        }
    }, [reconciled]);

    useEffect(() => {
        if (type === "Manufacturers") {
            setNavigateTo(`${Navigation.manufacturerReportWizardPage.to}${reportId}/${id}`);
        }
        else if (type === "Wholesalers") {
            setNavigateTo(`${Navigation.wholesalerReportWizardPage.to}${reportId}/${id}`);
        }
    }, [type, id])

    return <> 
        <div className="grid-x report-data-list__item alignItems--center show-for-medium">
            <div className="cell medium-3 display--flex report-data-list__name justifyContent--left">
                <p className="report-data-list__field">{name}</p>
            </div>
            <div className="cell medium-2 display--flex report-data-list__upload">
                <img src={IconUpload} onClick={() => navigate(navigateTo)} />
            </div>
            <div className="cell medium-6 display--flex report-data-list__filename">
                <p className="report-data-list__field">{filename}</p>        
            </div>
            <div className="cell medium-1 display--flex report-data-list__total">
                {totalFormatted && <p className="report-data-list__field">{totalFormatted}</p>}
                {!totalFormatted && <div className="report__section__field-empty report__section__field-empty--margin"></div>}
            </div>
        </div>

        <div className="grid-x alignItems--center show-for-small hide-for-medium">
            <div className="cell small-12 display--flex report-data-list__name textAlign--center margin__bottom--xs">
                <p className="report-data-list__field">{name}</p>
            </div>
            <div className="cell small-12 grid-x report-data-list__item__mobile justifyContent--center">
                <div className="cell small-12 display--flex report-data-list__upload">
                    <img src={IconUpload} onClick={() => navigate(navigateTo)}/>
                    { filename && 
                        <p className="report-data-list__field margin__left--xs">{filename}</p> 
                    }
                </div>
                <p className="margin__top--xs margin__bottom--xs report-data-list__field justifyContent--center">Total</p>
                <div className="cell small-12 display--flex report-data-list__total justifyContent--center">
                    {totalFormatted && <p className="report-data-list__field">{totalFormatted}</p>}
                    {!totalFormatted && <div className="report__section__field-empty report__section__field-empty--margin"></div>}
                </div>
            </div>
        </div>
    </>
};