import { useState, useEffect } from "react";
import { useHttp } from "../../../context/HttpProvider";
import FormInputCheckbox from "../../app/form/FormInputCheckbox";
import FormInputNumber from "../../app/form/FormInputNumber";
import { ContinueAction, useReportWizard } from "../ReportWizardProvider";
import MappingList from "./ColumnMappingList";
import httpStatusCodes from '../../../modules/httpStatusCodes';

export default function SpecifyColumnMappings({ header }) {

    const { mappingApi } = useHttp();
    const { setSmallContent } = useReportWizard();
    const [allMappingData, setAllMappingData] = useState();
    const [validationLookup, setValidationLookup] = useState();

    const { mappingData, setMappingData, reportUploadId,
        reportType, setContinueAction, fileContainsHeader,
        setFileContainsHeader, skipRows, setSkipRows, setValidToMoveForward
    } = useReportWizard();

    function updateMapping(mapping, value) {
        const columnNumber = parseInt(value);

        const copy = { ...mappingData };
        copy[mapping.value] = columnNumber;
        setMappingData(copy);

        checkValidation(copy, allMappingData.requiredFields);
    }

    function checkValidation(mappings, requiredFields, overrideValid = false) {
        const mappingsPopulated = Object.keys(mappings).length === requiredFields.length;
        if(overrideValid) {
            const isValidInitially = Object.values(mappings).every(x => x > 0);
            const validationLookup = {};
            for (const key of Object.keys(requiredFields)) {
                validationLookup[key] = mappingsPopulated;
            }
            setValidToMoveForward(mappingsPopulated && isValidInitially);
            return;
        }
        const validationLookup = {};
        for (const [key, value] of Object.entries(mappings)) {
            const unique = mappingValueUnique(mappings, value);
            const selected = !isNaN(value) && value > 0;
            validationLookup[key] = overrideValid ? true : (unique && selected);
        }
        const isValid = Object.values(validationLookup).every(x => x === true);
        setValidationLookup(validationLookup);
        setValidToMoveForward(isValid && mappingsPopulated);
    }

    function mappingValueUnique(mappings, value) {
        const values = Object.values(mappings);
        return values.filter(x => x === value).length <= 1;
    }

    function changeSkipRows(e) {
        let value = parseInt(e.target.value);
        if(isNaN(value)) {
            value = 0;
        }
        setSkipRows(value);
    }

    function changeFileContainsHeader(e) {
        saveSettings(e.target.checked, skipRows);
    }

    async function saveSettings(containsHeaders, rowsToSkip) {
        const response = await mappingApi.saveSettings(reportUploadId, reportType, containsHeaders, rowsToSkip);
        const success = response.status === httpStatusCodes.Ok;
        if(success) {
            getMappingSettings();
            getColumnMappings(false);
        }
    }

    async function getMappingSettings() {
        const response = await mappingApi.getSettings(reportUploadId, reportType);
        setFileContainsHeader(response.fileContainsHeader);
        setSkipRows(response.skipRows);
    }

    async function getColumnMappings(doValidation = true) {
        if (doValidation) {
            setValidToMoveForward(false);
        }
        const response = await mappingApi.getColumnMappings(reportUploadId, reportType);
        if (doValidation) {
            checkValidation(response.mappings, response.requiredFields, true);
            setMappingData({ ...response.mappings });
        }       
        setAllMappingData(response);
    }

    useEffect(() => {
        setContinueAction(ContinueAction.SaveColumnMappings);
        setSmallContent(false);
        if (mappingApi) {
            getMappingSettings();
            getColumnMappings();
        }
    }, [mappingApi])

    return <div>
        <h4 className="mapping__header margin__bottom--m">Map column names</h4>
        <div className="grid-x margin__bottom--l alignItems--center">
            <div className="cell small-12 medium-6 large-4 display--flex mapping__parse-option">
                <FormInputCheckbox className="left-align" id="chbFileContainsHeader" label="File Contains Header" labelClassName="margin__right--xxs" value={fileContainsHeader} onChange={changeFileContainsHeader} />
            </div>
            <div className="cell small-12 medium-6 large-4 display--flex mapping__skip-rows mapping__parse-option">
                <FormInputNumber id="skipRows" className="left-align" label="Skip rows" min={0} max={50} onInput={(e) => e.target.value = parseInt(e.target.value)} value={skipRows} onChange={changeSkipRows} onBlur={(e) => saveSettings(fileContainsHeader, e.target.value)} />
            </div>
        </div>
        <MappingList header={header} mappingData={allMappingData} specifiedMappings={mappingData} updateMapping={updateMapping} validationLookup={validationLookup} />
    </div>
}