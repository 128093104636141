import Close from '../../../assets/images/close.svg';
import React from 'react';

export default function Modal({ children, onClose, ...others }) {

    return (<div className="modal modal--open">
        <div className="modal__content-wrapper">
            <div className={`modal__content`}>
                <a onClick={onClose} title={'Close'}>
                    <img src={Close} alt={'Close'} className={`modal__close-button`} />
                </a>
                {children}
            </div>
        </div>
    </div>)
}