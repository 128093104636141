import React, { useState } from 'react';

export default function FormInputText({className, label, name, required, toolTipText, validateRule, validationError, prefix, ...other}) {
	const [valid, setValid] = useState();

	function validate(e){
		if(validateRule){
			setValid(validateRule(e.target.value))
		}
	}

    return <div className={`${className ? className : ''}`}>
        <label className="input-label" htmlFor={name}>
			{required ? `${label} *` : label}
		</label>
		<input className={`input input--regular input-text input-text--full display--block ${valid === false ? "input-text--error" : ""}`} 
            type="text" 
            name={name} 
            required={required} 
            {...other} />

        { valid === false && error !== "" &&
            <p className="small input__error">{error}</p>
        }
	</div>
}
