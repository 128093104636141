import IconView from '../../../assets/images/icon-view.png';
import {navigate} from '@reach/router';

export default function BasicListItem({id, name, isArchived, to}) {

    function navigateTo() {
        navigate(`${to}${id}`);
    }

    return  <div className={`basic-list-item display--flex alignItems--center grid-x ${isArchived ? "basic-list-item__archived" : ""}`} onClick={navigateTo}>
        <div className="cell small-8">
            <p className="justifyContent--flex-start basic-list-item__text">{name}</p>
        </div>
        <div className="cell small-4 textAlign--right">
            <img src={IconView} className="justifyContent--flex-end" />
        </div>
    </div>
}