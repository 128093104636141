
import { useState, useRef, useEffect } from "react";
import { useHttp } from "../../context/HttpProvider";
import httpStatusCodes from "../../modules/httpStatusCodes";
import Progress from "../app/loading/Progress";
import IconUpload from "../../assets/images/icon-upload.png";
import IconGreenTick from "../../assets/images/icon-green-tick.png";
import IconRedX from "../../assets/images/icon-red-x.png";
import { useReportWizard } from "./ReportWizardProvider";

const uploadStates = {
    NotStarted: 0,
    Errored: 1,
    Uploading: 2,
    Completed: 3
}

const iconLookup = {
    [uploadStates.NotStarted]: IconUpload,
    [uploadStates.Uploading]: IconUpload,
    [uploadStates.Completed]: IconGreenTick,
    [uploadStates.Errored]: IconRedX
}

export const validFileTypes = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel'
]

export default function UploadSpreadsheet() {
    const { reportsApi } = useHttp();
    const { setSmallContent, reportUploadId, reportType, setValidToMoveForward } = useReportWizard();
    const [file, setFile] = useState();
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadState, setUploadState] = useState(uploadStates.NotStarted);
    const [validationResult, setValidationResult] = useState();
    const fileInputRef = useRef();
    const maxProgress = 120;
    const [reportUpload, setReportUpload] = useState();

    function triggerFileChanged() {
        if (uploadState !== uploadStates.Uploading) {
            fileInputRef.current.click();
        }
    }

    function onFileChanged(e) {
        setUploadProgress(0);
        setFile(null);
        const file = e.target.files[0];
        if (file) {
            const result = validateFile(file);
            if (result?.isValid) {
                setUploadState(uploadStates.Uploading);
                setTimeout(() => setFile(file), 250);
            }
        }
    }

    function onUploadProgress(progressEvent) {
        let percentCompleted = Math.round((progressEvent.loaded * 100.0) / progressEvent.total);
        setUploadProgress(percentCompleted);
    }

    async function uploadFile() {
        setValidToMoveForward(false);
        const response = await reportsApi.uploadSpreadsheet(reportUploadId, reportType, file, onUploadProgress);
        if (response.status === httpStatusCodes.Ok) {
            setUploadProgress(maxProgress);
            setTimeout(() => {
                setUploadState(uploadStates.Completed);
                setValidToMoveForward(true);
                getReportUpload();
            }, 1000);
        } else {
            setUploadState(uploadStates.Errored);
            setValidationResult({
                isValid: false,
                errors: response.data
            });
        }
    }

    function validateFile(file) {
        let isValid = true;
        let errors = [];
        if (!validFileTypes.includes(file.type)) {
            isValid = false;
            errors.push('Invalid file type, please upload an xls/xlsx/csv');
        }
        if (isValid === false) {
            setUploadState(uploadStates.Errored);
        }
        const result = {
            isValid,
            errors
        };
        setValidationResult(result);
        return result;
    }

    async function getReportUpload() {
        var response = await reportsApi.getReportUpload(reportUploadId, reportType);
        if(response) {
            setReportUpload(response);
            if (response.filename) {
                setUploadState(uploadStates.Completed);
                setValidToMoveForward(true);
            }
            else {
                setValidToMoveForward(false);
            }
        }
    }

    useEffect(() => {
        setSmallContent(true);
        if (file) {
            uploadFile();
        }
    }, [file])

    useEffect(() => {
        if(reportsApi != null) {
            getReportUpload();
        }
    }, [reportsApi])

    return <div className="grid x">
        
            <div className="cell small-12">
            { reportUpload &&
                <h2 id='report-upload-name' className="margin__bottom--m">{reportUpload.name}</h2>
            }
                <div className="upload__browse-for-file margin__top--s margin__bottom--m" onClick={triggerFileChanged}>
                    {iconLookup[uploadState] && <img height={79} width={79} src={iconLookup[uploadState]} />}
                    {(!(reportUpload?.filename) && uploadState === uploadStates.NotStarted) && <p id='click-to-browse' className="margin__top--s">Click to browse</p>}
                    {uploadState === uploadStates.Uploading && <Progress className="upload__progress-bar margin__top--s" percent={uploadProgress} height="20px" maxCompleted={maxProgress} />}
                    {reportUpload?.filename && <p id='filename' className="margin__top--s">{reportUpload?.filename}</p>}
                    {uploadState === uploadStates.Errored && <div id='error-summary' className="margin__top--s">
                        <p className="textDecoration--underline">File upload validation errors</p>
                        <div id='upload-errors'>
                            {validationResult && validationResult?.errors?.length > 0 &&
                                validationResult.errors.map((e, i) => <p key={i}>{e}</p>)
                            }
                        </div>
                    </div>}
                    {(reportUpload?.filename || uploadState === uploadStates.Completed || uploadState === uploadStates.Errored) &&
                        <p id='click-to-reupload'>Click to browse / re-upload</p>
                    }
                </div>
                <input id='file-input' ref={fileInputRef} className="display--hidden" type="file" onChange={onFileChanged} />
            </div>
    </div>
}
