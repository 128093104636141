import {useAuth0} from "@auth0/auth0-react";
import {useEffect, useState} from "react";

function useDelayedLogout(duration = 30) {
    const {logout} = useAuth0();
    const [timeUntilLogout, setCounter] = useState(duration);

    useEffect(() => {
        if(timeUntilLogout > 0) {
            setTimeout(() => setCounter(timeUntilLogout - 1), 1000);
        }
        if(timeUntilLogout === 0) {
            forceLogout();
        }
    }, [timeUntilLogout]);

    function forceLogout() {
        localStorage.removeItem('userAuthId');
        localStorage.removeItem(process.env.ACCESS_TOKEN);
        logout({returnTo: window.location.origin});
    }

    return [timeUntilLogout]
}

export default useDelayedLogout;