import React, { useState, useEffect, useContext } from 'react';
import AuthApi from '../features/AuthApi';
import PracticesApi from '../features/PracticesApi';
import { PracticeUsersApi } from '../features/PracticeUsersApi';
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import HttpStatusCodes from '../modules/httpStatusCodes';
import WholesalersApi from "../features/WholesalersApi";
import { ManufacturersApi } from '../features/ManufacturersApi';
import ReportsApi from "../features/ReportsApi";
import MappingApi from "../features/MappingApi";
import PracticeUserHub from "../features/PracticeUserHub";
import {HubConnectionBuilder} from "@microsoft/signalr";
import {navigate} from "@reach/router";
import * as navigation from "../modules/navigation";
import {ProductsApi} from "../features/ProductsApi";
import {XeroTokensApi} from "../features/XeroTokensApi";

const HttpContext = React.createContext();

function HttpProvider(props) {
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState("Loading");
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(null);
    const [authApi, setAuthApi] = useState(null);
    const [practicesApi, setPracticesApi] = useState(null);
    const [practiceUsersApi, setPracticeUsersApi] = useState(null);
    const [wholesalersApi, setWholesalersApi] = useState(null);
    const [manufacturersApi, setManufacturersApi] = useState(null);
    const [reportsApi, setReportsApi] = useState(null);
    const [mappingApi, setMappingApi] = useState(null);
    const [productsApi, setProductsApi] = useState(null);
    const [xeroTokensApi, setXeroTokensApi] = useState(null);
    const [connection, setConnection] = useState(null);
    const [showDeleted, setShowDeleted] = useState(false);
    const [userAuthIdInLocalStorage, setUserAuthIdInLocalStorage] = useState(false);
    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        // PREVENT SIGNAL R RUNNING IN TESTS - RUNS ON LOCALHOST WITHOUT A PORT IN TESTS
        if(window.location.host !== 'localhost') {
            const options = {
                accessTokenFactory: () => localStorage.getItem(process.env.ACCESS_TOKEN)
            };
            const newConnection = new HubConnectionBuilder()
                .withUrl(`${process.env.REACT_APP_API_URL}${PracticeUserHub.route}`, options)
                .withAutomaticReconnect()
                .build();

            setConnection(newConnection);
        }
    }, [userAuthIdInLocalStorage, setUserAuthIdInLocalStorage])

    useEffect(() => {
        if(window.location.host !== 'localhost') {
            if (connection) {
                connection.start()
                    .then(() => {
                        connection.on(PracticeUserHub.events.UserDeleted, () => {
                            setShowDeleted(true);
                        })
                    })
                    .catch(e => console.log('Connection Failed: ', e));
            }
        }
    }, [connection])

    useEffect(() => {
        // Configure axios here
        setupUnauthorizedResponseInterception();
        if(window.location.host !== 'localhost') {
            setupErrorResponseInterception();
        }

        //create all apis here
        setAuthApi(new AuthApi(setLoading, setToastMessage));
        setPracticesApi(new PracticesApi(setLoading, setToastMessage));
        setPracticeUsersApi(new PracticeUsersApi(setLoading, setToastMessage));
        setWholesalersApi(new WholesalersApi(setLoading, setToastMessage));
        setManufacturersApi(new ManufacturersApi(setLoading, setToastMessage));
        setReportsApi(new ReportsApi(setLoading, setToastMessage));
        setMappingApi(new MappingApi(setLoading, setToastMessage));
        setProductsApi(new ProductsApi(setLoading, setToastMessage));
        setXeroTokensApi(new XeroTokensApi(setLoading, setToastMessage));
    }, []);
    

    function setupUnauthorizedResponseInterception() {
        axios.interceptors.response.use(
            undefined,
            async function (error) {
                if (error.response.status === HttpStatusCodes.Unauthorized) {
                    loginWithRedirect();
                }
                return Promise.reject(error);
            });
    }

    function setupErrorResponseInterception() {
        axios.interceptors.response.use(
            undefined,
            async function (error) {
                const skip = error.response.config.url.includes(root)
                if (!skip && error.response.status === HttpStatusCodes.NotFound) {
                    navigate(navigation.notFound.to);
                } else if (!skip && error.response.status === HttpStatusCodes.InternalServerError) {
                    navigate(navigation.internalServer.to);
                } else if (!skip && error.response.status === HttpStatusCodes.Forbidden) {
                    navigate(navigation.forbidden.to);
                }
                return Promise.reject(error);
            });
    }

    function toastTimer(show){
        if(show === true){
            clearTimeout(timer)
            setTimer(setTimeout(()=> setShowToast(true), 600))
        }else{
            clearTimeout(timer)
            setShowToast(false)
            setTimeout(() => setToastMessage("Loading"), 500)
        }
    }

    useEffect(() => {
        toastTimer(loading)
    }, [loading]);

    return <HttpContext.Provider value={{
        showToast: showToast,
        toastMessage: toastMessage,
        authApi: authApi,
        practicesApi: practicesApi,
        practiceUsersApi: practiceUsersApi,
        wholesalersApi: wholesalersApi,
        manufacturersApi: manufacturersApi,
        reportsApi: reportsApi,
        mappingApi: mappingApi,
        productsApi: productsApi,
        xeroTokensApi: xeroTokensApi,
        userAuthIdInLocalStorage: userAuthIdInLocalStorage,
        setUserAuthIdInLocalStorage: setUserAuthIdInLocalStorage,
        showDeleted: showDeleted,
    }} {...props} />
}

function useHttp() {
    const context = useContext(HttpContext)
    return context;
}

export { HttpProvider, useHttp }