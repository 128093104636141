import IconArchive from '../../assets/images/icon-archive.svg';
import {useHttp} from "../../context/HttpProvider";
import ModalConfirmation from "../app/modal/ModalConfirmation";
import {useState} from "react";
import HttpStatusCodes from "../../modules/httpStatusCodes";
import ReactTooltip from "react-tooltip";

export default function ProductListItem({product, getProducts}) {
    const {productsApi} = useHttp();
    const [showArchiveModal, setShowArchiveModal] = useState(false);
    const [archiveError, setArchiveError] = useState('');

    async function archiveProduct() {
        const response = await productsApi.archive(product.id);
        if(response.status === HttpStatusCodes.Ok) {
            setShowArchiveModal(false);
            getProducts(0);
        } else {
            setArchiveError(response.data[0]);
        }
    }

    return  <div className={`product-list__item display--flex alignItems--center grid-x ${product.isArchived ? "archived" : ""}`}>
        <div className="cell small-10">
            <div className="grid-x">
                <div className="cell small-10 medium-6">
                    <p className="justifyContent--flex-start basic-list-item__text">{product.name}</p>
                </div>
                <div className="cell small-10 medium-4">
                    <p className="justifyContent--flex-start basic-list-item__text">{product.discount}%</p>
                </div>
            </div>
        </div>
        <div className="cell small-2 textAlign--right display--flex justifyContent--flex-end">
            {!product.isArchived && <img src={IconArchive} className="justifyContent--flex-end product-list__archive" onClick={() => setShowArchiveModal(true)} data-tip="Archive"/>}
            <ReactTooltip effect="solid"/>
        </div>
        {showArchiveModal &&
            <ModalConfirmation action="Archive"
                               entityType="Product"
                               entityName={product.name}
                               isActive={showArchiveModal}
                               onConfirmed={archiveProduct}
                               onClose={() => setShowArchiveModal(false)}
                               error={archiveError}/>
       }
    </div>
}