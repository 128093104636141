export const stripTrailingSlash = (str) => {
    return str.endsWith('/') ?
        str.slice(0, -1) :
        str;
};

export const stripLeadingSlash = (str) => {
    return str.startsWith('/') ?
        str.slice(1) :
        str;
};